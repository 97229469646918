import React from "react";
import RelatedProducts from "../../../ProductComponent/RelatedProducts";
import MaterialGrade from "../../MaterialGrade.jsx";
import Layout from "../../../common/Layout.jsx";
import AnimatedBars from "../../AnimatedBars.jsx";
import sspipefitting from "../../../../assets/newImages/ssfitting.jpg";

const StainlessSteel347347HPipeFittings = () => {
  return (
    <div>
      <Layout>
        <div>
          <div className="text-fontDefault p-4 prod">
            <div className="customContainer  text-fontDefault p-6 shadow-md rounded-md flex flex-col lg:flex-row">
              <div className="lg:w-3/4 lg:pr-2">
                <h1 className="text-xl md:text-3xl font-bold mb-4 ">
                  Ready Stock Of SS 347 Pipe Fittings, Stainless Steel 347
                  Buttweld Fittings, Manufacturer of ASTM A403 WP347H Pipe
                  Elbow, 347 SS LR Elbow, SS 347H Elbow Supplier
                </h1>

                <div className="mb-6">
                  <p className="mb-2 text-justify">
                    Hariom Steel is a known trader and seller of SS 347 / 347H
                    Pipe Fittings, which have key characteristics such as
                    excellent resistance to crevice and pitting corrosion.
                    Stainless Steel UNS S34700 Buttweld Cross Pipe Fittings are
                    well-known for their sturdy structure, unrivalled quality,
                    and exceptional functioning; they are obtained from
                    market-reliable organisations. We supply this alloy with
                    legitimate test certifications to suit the quality sensitive
                    standards of our highly appreciated clientele. Nonetheless,
                    Stainless Steel WNR 1.4550 Buttweld Stub Ends Pipe Fittings
                    are widely employed in specific settings since their
                    fundamental advantage is that they may be linked to the pipe
                    without welding. The bore in this product, however, is
                    counter-bored to acknowledge pipe, with the width of the
                    left over bore being the same as the inside diameter of the
                    pipe.
                  </p>

                  <div className="mt-5 mb-5  flex items-center justify-center">
                    <img src={sspipefitting} alt="" width={400} />
                  </div>

                  <h2 className="mb-4 mt-4 font-bold text-2xl">
                  Stainless Steel 347/347H Pipe Fittings Specifications
                  </h2>
                  <div className="overflow-x-auto">
                  <table>
                                <tbody>
                                <tr>
                                <th>Specifications</th>
                                <td>ASTM A403 / ASME SA403</td>
                                </tr>
                                <tr>
                                <th>Dimensions</th>
                                <td>ASME/ANSI B16.9, ASME B16.28, MSS-SP-43, BS4504, BS4504, BS1560, BS10<br/>
                                ASME 16.11, MSS SP-79, 83, 95, 97, BS 3799</td>
                                </tr>
                                <tr>
                                <th>Size</th>
                                <td>Seamless Fittings: 1/2" - 10"<br/>
                                Welded Fittings: 1/2" - 48"</td>
                                </tr>
                                <tr>
                                <th>Thickness</th>
                                <td>SCH10, SCH20, SCH30, STD SCH40, SCH60, XS, SCH 80, SCH 100, SCH 120, SCH 140, SCH 160, XXS available with NACE MR 01-75</td>
                                </tr>
                                <tr>
                                <th>Manufacturing Standards</th>
                                <td>ASME/ANSI B16.9, ASME B16.28, MSS-SP-43<br/>
                                DIN : DIN2605, DIN2615, DIN2616, DIN2617, DIN28011, DN15-DN1200<br/>
                                BS : BS4504, BS4504, BS1560, BS10<br/>
                                JIS : JIS B2311, JIS B2312, JIS B2313, JIS B2316<br/>
                                GOST : GOST 17375, GOST 17376, GOST 30753, GOST 17378, GOST 17379, GOST 17380<br/>
                                EN: EN10253-1, EN10253-2</td>
                                </tr>
                                <tr>
                                <th>Types</th>
                                <td>Seamless / ERW / Welded / Fabricated </td>
                                </tr>
                                <tr>
                                <th>Connection</th>
                                <td>Welding</td>
                                </tr>
                                <tr>
                                <th>Bending Radius</th>
                                <td>R=1D, 2D, 3D, 5D, 6D, 8D, 10D or Custom </td>
                                </tr>
                                <tr>
                                <th>Elbow</th>
                                <td>Angle : 22.5 deg, 30deg, 45 deg, 90 deg, 180 deg, 1.0D, 1.5D, 2.0D, 2.5D, 3D, 4D, 5D, 6D, 7D-40D.<br/>
                                Standard: ASME B16.9, ASME B16.28, ASME B16.25, MSS SP-75 DIN2605 JISB2311 JISB2312 JISB2313, BS, GB, ISO</td>
                                </tr>
                                <tr>
                                <th>Manufacturing process</th>
                                <td>Push, Press, Forge, Cast, etc.</td>
                                </tr>
                                <tr>
                                <th>Specialized manufacturer of</th>
                                <td>90º Elbows, Couplings, Reducers, Inserts, Caps, Nipples, 45º Elbows, Tees, Crosses, Equal Tees &amp; Reducing Tees</td>
                                </tr>
                                <tr>
                                <th>Test Certificates</th>
                                <td>EN 10204/3.1B<br/>
                                Raw Materials Certificate<br/>
                                100% Radiography Test Report<br/>
                                Third Party Inspection Report, etc</td>
                                </tr>
                                <tr>
                                <th colSpan="2">Material Test Certificates (MTC) as per EN 10204 3.1 and EN 10204 3.2, Test Certificates certifying NACE MR0103, NACE MR0175</th>
                                </tr>
                                </tbody>
                                </table>
                  </div>

                  <h2 className="mb-4 mt-4 font-bold text-2xl">
                  Stainless Steel 347/347HL Butt weld Fittings Chemical Composition
                  </h2>
                  <div className="overflow-x-auto">
                  <table>
                        <tbody>
                        <tr  className="bg-primary text-white">
                        <td ><strong>Grade</strong></td>
                        <td >&nbsp;</td>
                        <td ><strong>C</strong></td>
                        <td ><strong>Mn</strong></td>
                        <td ><strong>Si</strong></td>
                        <td ><strong>P</strong></td>
                        <td ><strong>S</strong></td>
                        <td ><strong>Cr</strong></td>
                        <td ><strong>N</strong></td>
                        <td ><strong>Ni</strong></td>
                        <td ><strong>OTHER</strong></td>
                        </tr>
                        <tr>
                        <td ><strong>347</strong></td>
                        <td>Min</td>
                        <td>–</td>
                        <td>–</td>
                        <td>–</td>
                        <td>–</td>
                        <td>–</td>
                        <td>17.0</td>
                        <td>–</td>
                        <td>9.00</td>
                        <td>Nb=10(C+N)</td>
                        </tr>
                        <tr>
                        <td ><strong>347H</strong></td>
                        <td>Min</td>
                        <td>0.04</td>
                        <td>–</td>
                        <td>–</td>
                        <td>–</td>
                        <td>–</td>
                        <td>17.0</td>
                        <td>–</td>
                        <td>9.00</td>
                        <td>8xC min</td>
                        </tr>
                        </tbody>
                        </table>
                  </div>
                  <br />

                  <h2 className="mb-4 mt-4 font-bold text-2xl">
                  347/347H Stainless Steel Pipe Fittings Mechanical Properties
                  </h2>
                  <div className="overflow-x-auto">
                  <table>
                        <tbody>
                        <tr  className="bg-primary text-white">
                        <td rowSpan="2"><strong>Grade</strong></td>
                        <td rowSpan="2"><strong>Tensile Strength (MPa) min</strong></td>
                        <td rowSpan="2"><strong>Yield Strength 0.2% Proof (MPa) min</strong></td>
                        <td rowSpan="2"><strong>Elongation (% in 50mm) min</strong></td>
                        <td colSpan="2"><strong>Hardness</strong></td>
                        </tr>
                        <tr  className="bg-primary text-white">
                        <td><strong>Rockwell B (HR B) max</strong></td>
                        <td><strong>Brinell (HB) max</strong></td>
                        </tr>
                        <tr>
                        <td><strong>347/347H</strong></td>
                        <td>515</td>
                        <td>205</td>
                        <td>40%</td>
                        <td>92</td>
                        <td>201</td>
                        </tr>
                  
                            </tbody>
                        </table>
                  </div>
                  <br />

                  <h2 className="mb-4 mt-4 font-bold text-2xl">
                  Stainless Steel 347/347H Pipe Fittings Thickness Chart
                  </h2>
                  <div className="overflow-x-auto">
                    <table>
                      <tbody>
                        <tr className="bg-primary text-white">
                          <td colSpan="10">
                            Product range and theoretical weights (kg)
                          </td>
                        </tr>
                        <tr className="bg-primary text-white">
                          <td rowSpan="2">DN</td>
                          <td colSpan="3">Schedule 10S</td>
                          <td colSpan="3">Schedule 40S</td>
                          <td colSpan="3">Schedule 80S</td>
                        </tr>
                        <tr className="bg-primary text-white">
                          <td>90° Elbow</td>
                          <td>45° Elbow</td>
                          <td>Equal tee</td>
                          <td>90° Elbow</td>
                          <td>45° Elbow</td>
                          <td>Equal tee</td>
                          <td>90° Elbow</td>
                          <td>45° Elbow</td>
                          <td>Equal tee</td>
                        </tr>
                        <tr>
                          <td>8</td>
                          <td>0.02</td>
                          <td>0.01</td>
                          <td>0.03</td>
                          <td>0.03</td>
                          <td>0.02</td>
                          <td>0.06</td>
                          <td>0.04</td>
                          <td>0.03</td>
                          <td>0.07</td>
                        </tr>
                        <tr>
                          <td>10</td>
                          <td>0.03</td>
                          <td>0.02</td>
                          <td>0.05</td>
                          <td>0.03</td>
                          <td>0.02</td>
                          <td>0.03</td>
                          <td>0.06</td>
                          <td>0.04</td>
                          <td>0.09</td>
                        </tr>
                        <tr>
                          <td>15</td>
                          <td>0.06</td>
                          <td>0.03</td>
                          <td>0.09</td>
                          <td>0.08</td>
                          <td>0.04</td>
                          <td>0.10</td>
                          <td>0.10</td>
                          <td>0.05</td>
                          <td>0.14</td>
                        </tr>
                        <tr>
                          <td>20</td>
                          <td>0.07</td>
                          <td>0.03</td>
                          <td>0.13</td>
                          <td>0.08</td>
                          <td>0.04</td>
                          <td>0.17</td>
                          <td>0.11</td>
                          <td>0.05</td>
                          <td>0.20</td>
                        </tr>
                        <tr>
                          <td>25</td>
                          <td>0.14</td>
                          <td>0.08</td>
                          <td>0.28</td>
                          <td>0.15</td>
                          <td>0.11</td>
                          <td>0.29</td>
                          <td>0.22</td>
                          <td>0.14</td>
                          <td>0.38</td>
                        </tr>
                        <tr>
                          <td>32</td>
                          <td>0.23</td>
                          <td>0.11</td>
                          <td>0.49</td>
                          <td>0.26</td>
                          <td>0.17</td>
                          <td>0.59</td>
                          <td>0.40</td>
                          <td>0.23</td>
                          <td>0.68</td>
                        </tr>
                        <tr>
                          <td>40</td>
                          <td>0.30</td>
                          <td>0.17</td>
                          <td>0.68</td>
                          <td>0.40</td>
                          <td>0.23</td>
                          <td>0.86</td>
                          <td>0.51</td>
                          <td>0.29</td>
                          <td>1.02</td>
                        </tr>
                        <tr>
                          <td>50</td>
                          <td>0.50</td>
                          <td>0.25</td>
                          <td>0.85</td>
                          <td>0.70</td>
                          <td>0.4</td>
                          <td>1.28</td>
                          <td>0.91</td>
                          <td>0.59</td>
                          <td>1.59</td>
                        </tr>
                        <tr>
                          <td>65</td>
                          <td>0.85</td>
                          <td>0.48</td>
                          <td>1.41</td>
                          <td>1.40</td>
                          <td>0.77</td>
                          <td>2.19</td>
                          <td>1.81</td>
                          <td>0.99</td>
                          <td>3.13</td>
                        </tr>
                        <tr>
                          <td>80</td>
                          <td>1.25</td>
                          <td>0.63</td>
                          <td>1.77</td>
                          <td>2.20</td>
                          <td>1.08</td>
                          <td>3.31</td>
                          <td>2.97</td>
                          <td>1.50</td>
                          <td>4.45</td>
                        </tr>
                        <tr>
                          <td>90</td>
                          <td>1.70</td>
                          <td>0.75</td>
                          <td>2.67</td>
                          <td>2.83</td>
                          <td>1.42</td>
                          <td>4.08</td>
                          <td>4.00</td>
                          <td>2.00</td>
                          <td>5.44</td>
                        </tr>
                        <tr>
                          <td>100</td>
                          <td>2.10</td>
                          <td>1.08</td>
                          <td>3.46</td>
                          <td>4.47</td>
                          <td>2.09</td>
                          <td>5.27</td>
                          <td>6.18</td>
                          <td>2.81</td>
                          <td>7.71</td>
                        </tr>
                        <tr>
                          <td>150</td>
                          <td>5.45</td>
                          <td>2.72</td>
                          <td>8.07</td>
                          <td>10.89</td>
                          <td>5.44</td>
                          <td>10.99</td>
                          <td>16.32</td>
                          <td>8.16</td>
                          <td>13.61</td>
                        </tr>
                        <tr>
                          <td>200</td>
                          <td>10.20</td>
                          <td>5.33</td>
                          <td>15.65</td>
                          <td>21.54</td>
                          <td>10.77</td>
                          <td>20.91</td>
                          <td>33.11</td>
                          <td>16.56</td>
                          <td>28.12</td>
                        </tr>
                        <tr>
                          <td>250</td>
                          <td>18.15</td>
                          <td>9.75</td>
                          <td>26.46</td>
                          <td>38.56</td>
                          <td>19.27</td>
                          <td>35.38</td>
                          <td>51.71</td>
                          <td>25.86</td>
                          <td>49.90</td>
                        </tr>
                        <tr>
                          <td>300</td>
                          <td>25.80</td>
                          <td>13.62</td>
                          <td>39.46</td>
                          <td>59.42</td>
                          <td>29.71</td>
                          <td>62.14</td>
                          <td>79.38</td>
                          <td>39.69</td>
                          <td>83.91</td>
                        </tr>
                      </tbody>
                    </table>
                    <table>
                      <tbody>
                        <tr className="bg-primary text-white">
                          <td colSpan="10">
                            Product range and theoretical weights (kg)
                          </td>
                        </tr>
                        <tr className="bg-primary text-white">
                          <td rowSpan="2">DN</td>
                          <td colSpan="3">Schedule 10S</td>
                          <td colSpan="3">Schedule 40S</td>
                          <td colSpan="3">Schedule 80S</td>
                        </tr>
                        <tr className="bg-primary text-white">
                          <td>Con reducer</td>
                          <td>Ecc reducer</td>
                          <td>Reducing tee</td>
                          <td>Con reducer</td>
                          <td>Ecc reducer</td>
                          <td>Reducing tee</td>
                          <td>Con reducer</td>
                          <td>Ecc reducer</td>
                          <td>Reducing tee</td>
                        </tr>
                        <tr>
                          <td>40x.25</td>
                          <td>0.19</td>
                          <td>0.19</td>
                          <td>0.60</td>
                          <td>0.26</td>
                          <td>0.26</td>
                          <td>0.76</td>
                          <td>0.34</td>
                          <td>0.34</td>
                          <td>0.90</td>
                        </tr>
                        <tr>
                          <td>50x25</td>
                          <td>0.28</td>
                          <td>0.28</td>
                          <td>0.73</td>
                          <td>0.40</td>
                          <td>0.40</td>
                          <td>1.10</td>
                          <td>0.54</td>
                          <td>0.54</td>
                          <td>1.37</td>
                        </tr>
                        <tr>
                          <td>50x40</td>
                          <td>0.31</td>
                          <td>0.31</td>
                          <td>0.76</td>
                          <td>0.45</td>
                          <td>0.45</td>
                          <td>1.15</td>
                          <td>0.59</td>
                          <td>0.59</td>
                          <td>1.43</td>
                        </tr>
                        <tr>
                          <td>80x.50</td>
                          <td>0.55</td>
                          <td>0.55</td>
                          <td>1.56</td>
                          <td>1.00</td>
                          <td>1.00</td>
                          <td>2.91</td>
                          <td>1.79</td>
                          <td>1.79</td>
                          <td>3.91</td>
                        </tr>
                        <tr>
                          <td>100x50</td>
                          <td>0.78</td>
                          <td>0.78</td>
                          <td>2.94</td>
                          <td>1.50</td>
                          <td>1.50</td>
                          <td>4.48</td>
                          <td>1.95</td>
                          <td>1.95</td>
                          <td>6.55</td>
                        </tr>
                        <tr>
                          <td>100x80</td>
                          <td>0.87</td>
                          <td>0.87</td>
                          <td>3.04</td>
                          <td>1.74</td>
                          <td>1.74</td>
                          <td>4.64</td>
                          <td>2.33</td>
                          <td>2.33</td>
                          <td>6.79</td>
                        </tr>
                        <tr>
                          <td>150x80</td>
                          <td>1.82</td>
                          <td>1.82</td>
                          <td>6.86</td>
                          <td>3.95</td>
                          <td>3.95</td>
                          <td>9.68</td>
                          <td>5.51</td>
                          <td>5.51</td>
                          <td>11.57</td>
                        </tr>
                        <tr>
                          <td>150x100</td>
                          <td>1.96</td>
                          <td>1.96</td>
                          <td>7.10</td>
                          <td>4.07</td>
                          <td>4.07</td>
                          <td>11.94</td>
                          <td>5.96</td>
                          <td>5.96</td>
                          <td>11.97</td>
                        </tr>
                        <tr>
                          <td>200x100</td>
                          <td>3.01</td>
                          <td>3.01</td>
                          <td>13.46</td>
                          <td>6.55</td>
                          <td>6.55</td>
                          <td>17.98</td>
                          <td>9.23</td>
                          <td>9.23</td>
                          <td>24.18</td>
                        </tr>
                        <tr>
                          <td>200x150</td>
                          <td>3.19</td>
                          <td>3.19</td>
                          <td>14.08</td>
                          <td>6.74</td>
                          <td>6.74</td>
                          <td>18.82</td>
                          <td>10.12</td>
                          <td>10.12</td>
                          <td>25.31</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br />

                  <div>
                    <h3 className="text-xl font-semibold mb-4">
                      Applications:
                    </h3>

                    <AnimatedBars />
                  </div>
                </div>
              </div>
              {/* ------------ */}
              <div className="lg:w-1/4 mt-8 lg:mt-0 hidden sm:block">
                <img
                  src={sspipefitting}
                  alt="Anchor Bolts"
                  className="w-full h-auto mb-6 rounded shadow-lg"
                />
                <MaterialGrade />
                <RelatedProducts />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default StainlessSteel347347HPipeFittings;
