import React from "react";
import Layout from "../../common/Layout";
import RelatedProducts from "../../ProductComponent/RelatedProducts";
import MaterialGrade from "../MaterialGrade.jsx";

import AnimatedBars from "../AnimatedBars.jsx";
import inconel601 from "../../../assets/newImages/inconel-forged-fittings.jpg";

const Inconel601ForgedFittings = () => {
  return (
    <div>
      <Layout>
        <div>
          <div className="text-fontDefault p-4 prod">
            <div className="customContainer  text-fontDefault p-6 shadow-md rounded-md flex flex-col lg:flex-row">
              <div className="lg:w-3/4 lg:pr-2">
                <h1 className="text-xl md:text-3xl font-bold mb-4 ">
                  Ready Stock Of Inconel Alloy 601 Forged Fittings, Manufacturer
                  of ASTM A564 Inconel 601 Cross, Alloy 601 Forged Fittings,
                  Inconel 601 High Pressure Forged Fittings, Nickel Alloy 601
                  Full Couplings Supplier
                </h1>

                <div className="mb-6">
                  <p className="mb-2 text-justify">
                    Hariom Steel is aneminent distributor and stockist of Inconel
                    Alloy 601 Forged Fittings, which are more resistant to mild
                    corrosion and atmospheric corrosion than other steels.
                    Inconel UNS N06601 Threaded Union Fittings contains
                    molybdenum in addition to chromium and nickel, which makes
                    it more corrosion resistant. Flat-rolled materials
                    containing more molybdenum or molybdenum plus nitrogen are
                    also available, offering even greater crevice corrosion,
                    resistance to pitting, and general erosion. This steel's
                    austenitic structure provides outstanding toughness even at
                    cryogenic temperatures. Molybdenum has been added to this
                    alloy to improve corrosion resistance, particularly in
                    chloride-containing settings. It incorporates molybdenum in
                    applications that require improved pitting resistance and
                    overall corrosion resistance.
                  </p>

                  <div className="mt-5 mb-5  flex items-center justify-center">
                    <img src={inconel601} alt="" width={400} />
                  </div>

                  <h2 className="mb-4 mt-4 font-bold text-2xl">
                    Inconel 601 Forged Fittings Specifications
                  </h2>
                  <div className="overflow-x-auto">
                  <table>
                                <tbody>
                                <tr>
                                <td>Standards</td>
                                <td>ASTM B649 / ASME SB649 </td>
                                </tr>
                                <tr>
                                <td>Specifications</td>
                                <td>ASME B16.11, MSS SP-79, 83, 95, 97, BS 3799</td>
                                </tr>
                                <tr>
                                <td>Size Range</td>
                                <td>NPS 1/8″- 4″ (DN 6~100)</td>
                                </tr>
                                <tr>
                                <td>Rating Pressure</td>
                                <td>Threaded End - 2000/3000/6000 LBS. <br/>  Socket weld End – 3000/6000/9000 LBS.</td>
                                </tr>
                                <tr>
                                <td>Type</td>
                                <td>Socket Weld, Screwed, BSP Threaded & NPT Threaded</td>
                                </tr>
                                </tbody>
                                </table>
                  </div>

                  <h2 className="mb-4 mt-4 font-bold text-2xl">
                    Inconel 601 Threaded Forged Fittings Chemical Composition
                  </h2>
                  <div className="overflow-x-auto">
                  <table>
                        <thead>
                        <tr className="bg-primary text-white">
                        <th>Grade</th>
                        <th>C</th>
                        <th>MN</th>
                        <th>Si</th>
                        <th>P</th>
                        <th>S</th>
                        <th>Cu</th>
                        <th><strong>Cr</strong></th>
                        <th>Ni</th>
                        <th>Fe</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                        <td>
                        <strong>Inconel 601</strong>
                        </td>
                        <td>0.10 max</td>
                        <td>1.0 max</td>
                        <td>0.5 max</td>
                        <td>–</td>
                        <td>0.15 max</td>
                        <td>1.0 max</td>
                        <td>21.0 – 25.0</td>
                        <td>58.0 – 63.0</td>
                        <td>balance</td>
                        </tr>
                        </tbody>
                        </table>
                  </div>
                  <br />

                  <h2 className="mb-4 mt-4 font-bold text-2xl">
                    601 Inconel Forged Socket Weld Fittings Mechanical
                    Properties
                  </h2>
                  <div className="overflow-x-auto">
                  <table >
                        <thead>
                        <tr className="bg-primary text-white">
                        <th rowSpan="2">Grade</th>
                        <th rowSpan="2">Density</th>
                        <th rowSpan="2">Tensile Strength</th>
                        <th rowSpan="2">Yield Strenght 0.2% Proof<br/>(MPa) Min</th>
                        <th rowSpan="2">Elognation<br/>(%in 50mm) min</th>
                        <th colSpan="3">Melting Point</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                        <td>
                        <strong>Inconel 601</strong>
                        </td>
                        <td>8.1 g/cm3</td>
                        <td>Psi – 80,000 , MPa – 550</td>
                        <td>Psi – 30,000 , MPa – 205</td>
                        <td>30 %</td><td colSpan="2">1413 °C (2580 °F)</td>
                        </tr>
                        </tbody>
                        </table>
                  </div>
                  <br />

                  <h2 className="mb-4 mt-4 font-bold text-2xl">
                    Inconel 601 Forged Fittings Size Range & Dimensions
                  </h2>
                  <div className="overflow-x-auto">
                    <table>
                      <thead>
                        <tr className="bg-primary text-white">
                          <th>DN-NPT</th>
                          <th>MAJOR DIAMETER MM</th>
                          <th>TAPPING /DRILL SIZE MM</th>
                          <th>TPI</th>
                          <th>PITCH MM</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1/16″</td>

                          <td>7.895</td>

                          <td>6.00</td>

                          <td>27</td>

                          <td>0.941</td>
                        </tr>

                        <tr>
                          <td>1/8″</td>

                          <td>10.242</td>

                          <td>8.25</td>

                          <td>27</td>

                          <td>0.941</td>
                        </tr>

                        <tr>
                          <td>1/4″</td>

                          <td>13.616</td>

                          <td>10.70</td>

                          <td>18</td>

                          <td>1.411</td>
                        </tr>

                        <tr>
                          <td>3/8″</td>

                          <td>17.055</td>

                          <td>14.10</td>

                          <td>18</td>

                          <td>1.411</td>
                        </tr>

                        <tr>
                          <td>1/2″</td>

                          <td>21.223</td>

                          <td>17.40</td>

                          <td>14</td>

                          <td>1.814</td>
                        </tr>

                        <tr>
                          <td>3/4″</td>

                          <td>26.568</td>

                          <td>22.60</td>

                          <td>14</td>

                          <td>1.814</td>
                        </tr>

                        <tr>
                          <td>1″</td>

                          <td>33.228</td>

                          <td>28.50</td>

                          <td>11.5</td>

                          <td>2.209</td>
                        </tr>

                        <tr>
                          <td>1¼”</td>

                          <td>41.985</td>

                          <td>37.00</td>

                          <td>11.5</td>

                          <td>2.209</td>
                        </tr>

                        <tr>
                          <td>1½”</td>

                          <td>48.054</td>

                          <td>43.50</td>

                          <td>11.5</td>

                          <td>2.209</td>
                        </tr>

                        <tr>
                          <td>2″</td>

                          <td>60.092</td>

                          <td>55.00</td>

                          <td>11.5</td>

                          <td>2.209</td>
                        </tr>

                        <tr>
                          <td>2½”</td>

                          <td>72.699</td>

                          <td>65.50</td>

                          <td>8</td>

                          <td>3.175</td>
                        </tr>

                        <tr>
                          <td>3″</td>

                          <td>88.608</td>

                          <td>81.50</td>

                          <td>8</td>

                          <td>3.175</td>
                        </tr>

                        <tr>
                          <td>3½”</td>

                          <td>101.316</td>

                          <td>94.30</td>

                          <td>8</td>

                          <td>3.175</td>
                        </tr>

                        <tr>
                          <td>4″</td>

                          <td>113.973</td>

                          <td>107.00</td>

                          <td>8</td>

                          <td>3.175</td>
                        </tr>

                        <tr>
                          <td>5″</td>

                          <td>141.300</td>

                          <td>134.384</td>

                          <td>8</td>

                          <td>3.175</td>
                        </tr>

                        <tr>
                          <td>6″</td>

                          <td>168.275</td>

                          <td>161.191</td>

                          <td>8</td>
                          <td>3.175</td>
                        </tr>
                        <tr>
                          <td>8″</td>
                          <td>219.075</td>
                          <td>211.673</td>
                          <td>8</td>
                          <td>3.175</td>
                        </tr>
                        <tr>
                          <td>10″</td>
                          <td>273.050</td>
                          <td>265.311</td>
                          <td>8</td>
                          <td>3.175</td>
                        </tr>
                        <tr>
                          <td>12″</td>
                          <td>323.850</td>
                          <td>315.793</td>
                          <td>8</td>
                          <td>3.175</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br />

                  <div>
                    <h3 className="text-xl font-semibold mb-4">
                      Applications:
                    </h3>

                    <AnimatedBars />
                  </div>
                </div>
              </div>
              {/* ------------ */}
              <div className="lg:w-1/4 mt-8 lg:mt-0 hidden sm:block">
                <img
                  src={inconel601}
                  alt="Anchor Bolts"
                  className="w-full h-auto mb-6 rounded shadow-lg"
                />
                <MaterialGrade />
                <RelatedProducts />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Inconel601ForgedFittings;
