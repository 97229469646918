import React from 'react'
import Layout from '../../../common/Layout.jsx'
import MaterialGrade from '../../MaterialGrade.jsx'
import RelatedProducts from '../../../ProductComponent/RelatedProducts.jsx'
import AnimatedBars from '../../AnimatedBars.jsx'
import ssfrodgefit from "../../../../assets/newImages/ss-forged-fitting5.jpg"

const StainlessSteel304LForgedFittings = () => {
  return (
    <Layout>
    <div>
      <div className="text-fontDefault p-4 prod">
        <div className="customContainer  text-fontDefault p-6 shadow-md rounded-md flex flex-col lg:flex-row">
          <div className="lg:w-3/4 lg:pr-2">
            <h1 className="text-xl md:text-3xl font-bold mb-4 ">
            SS 304L Forged Fittings, Manufacturer of ASTM A403 WP304L Cross, 304L SS High Pressure Forged Fittings, Stainless Steel 304L Full Couplings, Ready Stock Of SS 304L Forged Steel Fittings.        </h1>

            <div className="lg:w-1/4 mt-8 lg:mt-0 sm:block md:hidden">
              <img
                src={ssfrodgefit}
                alt="Anchor Bolts"
                className="w-full h-auto mb-6 rounded shadow-lg"
              />
            </div>

            <div className="mb-6">
              <p className="mb-2 text-justify">
              Hariom Steel is a known distributor and exporter of SS 304L Forged Fittings, which are provided with suitable test certifications for finished goods and standard raw materials.     </p>
              <p className="mb-2 text-justify">
              Stainless Steel UNS S30403 Socket Weld Couplings Fittings are also utilised in condensers, chemical equipment, sea water equipment, pharmaceutical equipment, and other similar applications. They have key characteristics such as strong resistance to crevice and pitting corrosion, first-rate resistance to SCC, and so on. It is also resistant to intergranular corrosion and has excellent weldability and formability. They are tougher and stronger than regular steel, but not as corrosion resistant. It's a high-alloy exceptional stainless steel grade with excellent resistance to sulphuric acid and chloride stress corrosion cracking. It contains nickel, molybdenum, copper, and chromium, all of which contribute to its outstanding corrosion resistance.
              </p>

              <div className="mt-5 mb-5  flex items-center justify-center">
                <img src={ssfrodgefit} alt="" />
              </div>

              <h2 className="mb-4 mt-4 font-bold text-2xl">Stainless Steel 304L Forged Fittings Specifications</h2>
                                <div className="overflow-x-auto">
                                <table>
                                <tbody>
                                <tr>
                                <td>Standards</td>
                                <td>ASTM A182 / ASME SA182 </td>
                                </tr>
                                <tr>
                                <td>Specifications</td>
                                <td>ASME B16.11, MSS SP-79, 83, 95, 97, BS 3799</td>
                                </tr>
                                <tr>
                                <td>Size Range</td>
                                <td>NPS 1/8″- 4″ (DN 6~100)</td>
                                </tr>
                                <tr>
                                <td>Rating Pressure</td>
                                <td>Threaded End - 2000/3000/6000 LBS. <br />  Socket weld End – 3000/6000/9000 LBS.</td>
                                </tr>
                                <tr>
                                <td>Type</td>
                                <td>Socket Weld, Screwed, BSP Threaded & NPT Threaded</td>
                                </tr>
                                </tbody>
                                </table>
                                </div>

                                <h2 className="mb-4 mt-4 font-bold text-2xl">Stainless Steel 304L Threaded Forged Fittings Chemical Composition</h2>
                        <div className="overflow-x-auto">
                        <table>
                        <tbody>
                        <tr className="bg-primary text-white">
                        <td colSpan="2"><strong>Grade</strong></td>
                        <td><strong>C</strong></td>
                        <td><strong>Mn</strong></td>
                        <td><strong>Si</strong></td>
                        <td><strong>P</strong></td>
                        <td><strong>S</strong></td>
                        <td><strong>Cr</strong></td>
                        <td><strong>Mo</strong></td>
                        <td><strong>Ni</strong></td>
                        <td><strong>N</strong></td>
                        </tr>
                        <tr>
                        <td rowSpan="2"><strong>304L</strong></td>
                        <td>min.</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>18.0</td>
                        <td rowSpan="2">-</td>
                        <td>8.0</td>
                        <td>-</td>
                        </tr>
                        <tr>
                        <td>max.</td>
                        <td>0.030</td>
                        <td>2.0</td>
                        <td>0.75</td>
                        <td>0.045</td>
                        <td>0.030</td>
                        <td>20.0</td>
                        <td>12.0</td>
                        <td>0.10</td>
                        </tr>
                        </tbody>
                        </table>
                        </div><br />

                        <h2 className="mb-4 mt-4 font-bold text-2xl">304L Stainless Steel Forged Socket Weld Fittings Mechanical Properties</h2>
                        <div className="overflow-x-auto">
                        <table>
                        <tbody>
                        <tr className="bg-primary text-white">
                        <td rowSpan="2"><strong>Grade</strong></td>
                        <td rowSpan="2"><strong>Tensile Strength (MPa)    min</strong></td>
                        <td rowSpan="2"><strong>Yield Strength 0.2%    Proof (MPa) min</strong></td>
                        <td rowSpan="2"><strong>Elongation (% in 50mm)    min</strong></td>
                        <td colSpan="2"><strong>Hardness</strong></td>
                        </tr>
                        <tr>
                        <td><strong>Rockwell B (HR B) max</strong></td>
                        <td><strong>Brinell (HB) max</strong></td>
                        </tr>
                        <tr>
                        <td><strong>304L</strong></td>
                        <td>485</td>
                        <td>170</td>
                        <td>40</td>
                        <td>92</td>
                        <td>201</td>
                        </tr>
                        </tbody>
                        </table>
                        </div><br />

                        <h2 className="mb-4 mt-4 font-bold text-2xl">Stainless Steel 304L Forged Fittings Size Range & Dimensions</h2> 
            <div className="overflow-x-auto">
            <table>
            <thead>
            <tr className="bg-primary text-white">
            <th>DN-NPT</th>
            <th>MAJOR DIAMETER  MM</th>
            <th>TAPPING /DRILL SIZE MM</th>
            <th>TPI</th>
            <th>PITCH MM</th>
            </tr>
            </thead>
            <tbody>

            <tr>

            <td>1/16″</td>

            <td>7.895</td>

            <td>6.00</td>

            <td>27</td>

            <td>0.941</td>

            </tr>

            <tr>

            <td>1/8″</td>

            <td>10.242</td>

            <td>8.25</td>

            <td>27</td>

            <td>0.941</td>

            </tr>

            <tr>

            <td>1/4″</td>

            <td>13.616</td>

            <td>10.70</td>

            <td>18</td>

            <td>1.411</td>

            </tr>

            <tr>

            <td>3/8″</td>

            <td>17.055</td>

            <td>14.10</td>

            <td>18</td>

            <td>1.411</td>

            </tr>

            <tr>

            <td>1/2″</td>

            <td>21.223</td>

            <td>17.40</td>

            <td>14</td>

            <td>1.814</td>

            </tr>

            <tr>

            <td>3/4″</td>

            <td>26.568</td>

            <td>22.60</td>

            <td>14</td>

            <td>1.814</td>

            </tr>

            <tr>

            <td>1″</td>

            <td>33.228</td>

            <td>28.50</td>

            <td>11.5</td>

            <td>2.209</td>

            </tr>

            <tr>

            <td>1¼”</td>

            <td>41.985</td>

            <td>37.00</td>

            <td>11.5</td>

            <td>2.209</td>

            </tr>

            <tr>

            <td>1½”</td>

            <td>48.054</td>

            <td>43.50</td>

            <td>11.5</td>

            <td>2.209</td>

            </tr>

            <tr>

            <td>2″</td>

            <td>60.092</td>

            <td>55.00</td>

            <td>11.5</td>

            <td>2.209</td>

            </tr>

            <tr>

            <td>2½”</td>

            <td>72.699</td>

            <td>65.50</td>

            <td>8</td>

            <td>3.175</td>

            </tr>

            <tr>

            <td>3″</td>

            <td>88.608</td>

            <td>81.50</td>

            <td>8</td>

            <td>3.175</td>

            </tr>

            <tr>

            <td>3½”</td>

            <td>101.316</td>

            <td>94.30</td>

            <td>8</td>

            <td>3.175</td>

            </tr>

            <tr>

            <td>4″</td>

            <td>113.973</td>

            <td>107.00</td>

            <td>8</td>

            <td>3.175</td>

            </tr>

            <tr>

            <td>5″</td>

            <td>141.300</td>

            <td>134.384</td>

            <td>8</td>

            <td>3.175</td>

            </tr>

            <tr>

            <td>6″</td>

            <td>168.275</td>

            <td>161.191</td>

            <td>8</td>
            <td>3.175</td>
            </tr>
            <tr>
            <td>8″</td>
            <td>219.075</td>
            <td>211.673</td>
            <td>8</td>
            <td>3.175</td>
            </tr>
            <tr>
            <td>10″</td>
            <td>273.050</td>
            <td>265.311</td>
            <td>8</td>
            <td>3.175</td>
            </tr>
            <tr>
            <td>12″</td>
            <td>323.850</td>
            <td>315.793</td>
            <td>8</td>
            <td>3.175</td>
            </tr>
            </tbody>
            </table>
            </div><br />








            <div className="mb-8">
    <h3 className="text-xl font-semibold mb-4">
       Applications:
    </h3>
    <AnimatedBars />
    
  </div>


            </div>
          </div>
{/* ------------ */}
          <div className="lg:w-1/4 mt-8 lg:mt-0 hidden sm:block">
            <img
              src={ssfrodgefit}
              alt="Anchor Bolts"
              className="w-full h-auto mb-6 rounded shadow-lg"
            />
            <MaterialGrade />
            <RelatedProducts />
          </div>
        </div>
      </div>
    </div>
  </Layout>
  )
}

export default StainlessSteel304LForgedFittings
