import React from "react";
import AnimatedBars from "../../AnimatedBars";

import RelatedProducts from "../../../ProductComponent/RelatedProducts";
import ssoutlets from "../../../../assets/newImages/ssoutlets.jpg";
import Layout from "../../../common/Layout";
import MaterialGrade from "../../MaterialGrade.jsx";

const StainlessSteel316Outlets = () => {
  return (
    <Layout>
    <div>
      <div className="text-fontDefault p-4 prod">
        <div className="customContainer  text-fontDefault p-6 shadow-md rounded-md flex flex-col lg:flex-row">
          <div className="lg:w-3/4 lg:pr-2">
            <h1 className="text-xl md:text-3xl font-bold mb-4 ">
            SS 316 OUTLET, STAINLESS STEEL 316 FLEXIBLE OUTLET, MANUFACTURER OF ASTM A182 WP316 FLEXOLET, READY STOCK OF SS 316 THREADOLET, 316 SS THREADING OUTLET SUPPLIER
            </h1>
            {/* 
          <div className="lg:w-1/4 mt-8 lg:mt-0 sm:block md:hidden">
            <img
              src={ssoutlets}
              alt="Anchor Bolts"
              className="w-full h-auto mb-6 rounded shadow-lg"
            />
          </div> */}

            <div className="mb-6">
              <p className="mb-2 text-justify">
                Hariom Steel is a recognised trader and seller of SS 316 Outlets, which are provided to our customers at very affordable prices.

Stainless Steel UNS S31600 Outlets is suggested for uses where subsequent and sensitization erosion caused by elevated condensates or temperature gases during closure is likely. It is an austenitic steel with strong ductility and weldability as well as exceptional high temperature characteristics. It is one of the most commonly used types of heat resistant stainless steel in a variety of industries. It is frequently used for building components that must be resistant to scaling up to 1050 degrees Celsius. It has good oxidation protection at high temperatures, making it ideal for furnace tracks. It is well-known for its ability to endure high-temperature uses and provides users with a variety of advantages.
              </p>

              <div className="mt-5 mb-5  flex items-center justify-center">
                <img src={ssoutlets} alt=""  />
              </div>

              {/* specification */}
              <h2 className="mb-4 mt-4 font-bold text-2xl">Stainless Steel 316 Outlets Specifications</h2>
                                <div className="overflow-x-auto">
                                <table className="table table-striped table-bordered">
                                <tbody>                    
                                <tr>
                                <th>SS 316 Outlet Fittings Standard</th>
                                <td>ASTM A182  / ASME SA182</td>
                                </tr>
                                <tr>
                                <th>Stainless Steel 316 / Outlet Fittings Dimensions</th>
                                <td>ASME / ANSI B16.11, MSS-SP-97, MSS-SP-79, JIS B2316, BS 3799 etc</td>
                                </tr>
                                <tr>
                                <th>316 SS Outlet Fittings Size</th>
                                <td>1/8″-4″ (DN6-DN100)</td>
                                </tr>
                                <tr>
                                <th>316 SS Outlet Fittings Connect Type</th>
                                <td>Welded, threaded, BW, SW</td>
                                </tr>
                                <tr>
                                <th>316 SS Outlet Pipe Diameter</th>
                                <td>DN15 ~ DN40</td>
                                </tr>
                                <tr>
                                <th>SS 316 Outlet Fittings</th>
                                <td>2000 LBS, 3000 LBS, 6000 LBS, 9000 LBS</td>
                                </tr>
                                <tr>
                                <th>Stainless Steel 316 Outlet Process</th>
                                <td>Free forging, plate cutting, forged</td>
                                </tr>
                                <tr>
                                <th>Origin of 316 Stainless Steel Outlet</th>
                                <td>Indian Origin, European Origin, Japanese Origin</td>
                                </tr>
                                <tr>
                                <th> 316 Outlet Fittings Packing</th>
                                <td>Packed in Wooden Boxes, Bubble wraps, Steel Strips Bundled, or as per Customers Requests</td>
                                </tr>
                                <tr>
                                <th>Test Certificates</th>
                                <td>
                                Mill Test Certificates<br />
                                316 Outlet Fittings Chemical Reports<br />
                                EN 10204 3.1<br />
                                Mechanical Reports,<br />
                                Destructive Test Report<br />
                                Non Destructive Test Reports<br />
                                316L Outlet Fittings PMI Test Reports<br />
                                316 SS Outlet Fittings Visual Inspection Reports<br />
                                NABL Approved Lab Reports
                                </td>
                                </tr>
                                <tr>
                                <th colSpan="2">Material Test Certificates (MTC) as per EN 10204 3.1 and EN 10204 3.2 Test Certificates certifying NACE MR0103, NACE MR0175</th>
                                </tr>
                                </tbody>
                                </table>
                                </div><br />
                                <h2 className="mb-4 mt-4 font-bold text-2xl">SS 316 Outlets Equivalent Grades</h2>
                        <div className="overflow-x-auto">
                        <table ><tbody><tr className="bg-primary text-white"><td><strong>STANDARD</strong></td><td><strong>UNS </strong></td><td><strong>WERKSTOFF NR.</strong></td><td><strong>AFNOR</strong></td><td><strong>EN</strong></td><td><strong>JIS</strong></td><td><strong>BS</strong></td><td><strong>GOST</strong></td></tr><tr><td><strong>SS 316 </strong></td><td>S31600</td><td>1.4401 / 1.4436</td><td>Z7CND17‐11‐02</td><td>X5CrNiMo17-12-2 / X3CrNiMo17-13-3</td><td>SUS 316</td><td>316S31 / 316S33 </td><td>-</td></tr></tbody></table>
                        </div><br />
                        
                        <h2 className="mb-4 mt-4 font-bold text-2xl">Stainless Steel 316 Outlets Chemical Composition</h2>
                        <div className="overflow-x-auto">
                        <table ><tbody><tr className="bg-primary text-white"><td colSpan="2"><strong>Grade</strong></td><td><strong>C</strong></td><td><strong>Mn</strong></td><td><strong>Si</strong></td><td><strong>P</strong></td><td><strong>S</strong></td><td><strong>Cr</strong></td><td><strong>Mo</strong></td><td><strong>Ni</strong></td><td><strong>N</strong></td></tr><tr><td rowSpan="2"><strong>SS 316</strong></td><td>min.</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>16.0</td><td rowSpan="2">2.00 - 3.00</td><td>10.0</td><td>-</td></tr><tr><td>max.</td><td>18.0</td><td>2.00</td><td>0.75</td><td>0.045</td><td>0.03</td><td>18.0</td><td>14.0</td><td>0.10</td></tr></tbody></table>
                        </div><br />

                        <h2 className="mb-4 mt-4 font-bold text-2xl">316 Stainless Steel Outlets Mechanical Properties</h2>
                        <div className="overflow-x-auto">
                        <table ><tbody><tr className="bg-primary text-white"><td rowSpan="2"><strong>Grade</strong><p></p></td><td rowSpan="2"><strong>Tensile Strength (MPa)    min</strong></td><td rowSpan="2"><strong>Yield Strength 0.2%    Proof (MPa) min</strong></td><td rowSpan="2"><strong>Elongation (% in 50mm)    min</strong></td><td colSpan="2"><strong>Hardness</strong></td></tr><tr className="bg-primary text-white"><td><strong>Rockwell B (HR B) max</strong></td><td><strong>Brinell (HB) max</strong></td></tr><tr><td><strong>SS 316</strong></td><td>515</td><td>205</td><td>40</td><td>95</td><td>217</td></tr></tbody></table>
                        </div><br /><h2 className="mb-4 mt-4 font-bold text-2xl">Stainless Steel 316 Outlets Standards</h2> 
            <div className="overflow-x-auto">
            <table className="table">
            <tbody>
            <tr>
            <th>ASTM A182/A182M</th>
            <td>Wrought Austenitic SS 316 Outlet Piping Fittings</td>
            </tr>
            <tr>
            <th>ASME SA182 – ASTM A182</th>
            <td>Standard Specification for Wrought Austenitic Stainless Steel 316 Outlet Piping Fittings</td>
            </tr>
            <tr>
            <th>MSS SP-97</th>
            <td>Integrally Reinforced Forged Branch Outlet Fittings – Socket Welding, Threaded and Buttwelding Ends</td>
            </tr>
            <tr>
            <th>BS3799 –</th>
            <td>Specification for Outlet Fittings for the petroleum industry</td>
            </tr>
            <tr>
            <th>ASTM A182 – ASME SA182</th>
            <td>Standard Specification for Wrought Austenitic SS Outlet Fittings</td>
            </tr>
            <tr>
            <th>ASME Standard</th>
            <td>ASME/ANSI B16.11, MSS-SP-97</td>
            </tr>
            <tr>
            <th>DIN Standard</th>
            <td>DIN28011, DIN2617, DIN2616, DIN2615, DIN2605</td>
            </tr>
            <tr>
            <th>EN Standard</th>
            <td>EN10253-2, EN10253-1</td>
            </tr>
            <tr>
            <th>ASME B16.11</th>
            <td>Threaded, Welded, SW, BW</td>
            </tr>
            </tbody>
            </table>
            </div><br />

            <h2 className="mb-4 mt-4 font-bold text-2xl">Stainless Steel 316 Outlets Weldolet Dimensions</h2> 
            <div className="overflow-x-auto">
            <table>
            <tbody>
            <tr className="bg-primary text-white">
            <th>NPS</th>
            <th>A</th>
            <th>B</th>
            <th>C</th>
            <th>D</th>
            </tr>
            <tr>
            <td>1</td>
            <td>1.1/16<br />
            26.99</td>
            <td>2<br />
            50.80</td>
            <td>1.7/16<br />
            36.51</td>
            <td>1.1/32<br />
            26.19</td>
            </tr>
            <tr>
            <td>1/2</td>
            <td>3/4<br />
            19.05</td>
            <td>1.3/8<br />
            34.93</td>
            <td>15/16<br />
            23.81</td>
            <td>5/8<br />
            15.88</td>
            </tr>
            <tr>
            <td>3/4</td>
            <td>7/8<br />
            22.23</td>
            <td>1.5/8<br />
            41.28</td>
            <td>1.3/16<br />
            30.16</td>
            <td>13/16<br />
            20.64</td>
            </tr>
            <tr>
            <td>1¼</td>
            <td>1¼<br />
            31.75</td>
            <td>2.3/8<br />
            60.3</td>
            <td>1.3/4<br />
            44.45</td>
            <td>1.3/8<br />
            34.93</td>
            </tr>
            <tr>
            <td>1½</td>
            <td>1.5/16<br />
            33.34</td>
            <td>2.7/8<br />
            73</td>
            <td>2<br />
            50.80</td>
            <td>1.5/8<br />
            41.28</td>
            </tr>
            <tr>
            <td>2</td>
            <td>1½<br />
            38.10</td>
            <td>3½<br />
            88.90</td>
            <td>2.9/16<br />
            65.09</td>
            <td>2.1/16<br />
            52.39</td>
            </tr>
            <tr>
            <td>2½</td>
            <td>1.5/8<br />
            41.28</td>
            <td>4.1/16<br />
            103.19</td>
            <td>3<br />
            76.20</td>
            <td>2.7/16<br />
            61.91</td>
            </tr>
            <tr>
            <td>3</td>
            <td>1.3/4<br />
            44.45</td>
            <td>4.13/16<br />
            122.24</td>
            <td>3.11/16<br />
            93.66</td>
            <td>3.1/16<br />
            77.79</td>
            </tr>
            <tr>
            <td>3½</td>
            <td>2<br />
            50.80</td>
            <td>5.3/8<br />
            136.53</td>
            <td>3.7/8<br />
            98.43</td>
            <td>3.9/16<br />
            90.49</td>
            </tr>
            <tr>
            <td>4</td>
            <td>2<br />
            50.80</td>
            <td>6<br />
            152.40</td>
            <td>4.3/4<br />
            120.65</td>
            <td>4<br />
            101.60</td>
            </tr>
            <tr>
            <td>5</td>
            <td>2¼<br />
            57.15</td>
            <td>7.1/8<br />
            180.98</td>
            <td>5.9/16<br />
            141.29</td>
            <td>5.1/16<br />
            128.59</td>
            </tr>
            <tr>
            <td>6</td>
            <td>2.3/8<br />
            60.3</td>
            <td>8½<br />
            215.90</td>
            <td>6.11/16<br />
            169.86</td>
            <td>6.1/16<br />
            153.99</td>
            </tr>
            <tr>
            <td>8</td>
            <td>2.3/4<br />
            69.85</td>
            <td>10.3/8<br />
            263.53</td>
            <td>8.11/16<br />
            220.66</td>
            <td>7.15/16<br />
            201.61</td>
            </tr>
            <tr>
            <td>10</td>
            <td>3.1/16<br />
            77.79</td>
            <td>12.9/16<br />
            319.09</td>
            <td>10.13/16<br />
            274.64</td>
            <td>10<br />
            254.00</td>
            </tr>
            <tr>
            <td>12</td>
            <td>3.3/8<br />
            85.73</td>
            <td>14.7/8<br />
            377.83</td>
            <td>12.13/16<br />
            325.44</td>
            <td>12<br />
            316.80</td>
            </tr>
            <tr>
            <td>14</td>
            <td>3½<br />
            88.90</td>
            <td>16.1/8<br />
            409.58</td>
            <td>14.1/16<br />
            357.19</td>
            <td>13¼<br />
            336.55</td>
            </tr>
            <tr>
            <td>16</td>
            <td>3.11/16<br />
            93.66</td>
            <td>18¼<br />
            463.55</td>
            <td>16.1/16<br />
            407.99</td>
            <td>15¼<br />
            387.35</td>
            </tr>
            <tr>
            <td>18</td>
            <td>4.1/16<br />
            103.19</td>
            <td>20.3/4<br />
            527.05</td>
            <td>18.5/8<br />
            473.08</td>
            <td>17¼<br />
            438.15</td>
            </tr>
            <tr>
            <td>20</td>
            <td>4.5/8<br />
            117.48</td>
            <td>23.1/16<br />
            585.79</td>
            <td>20.1/16<br />
            509.59</td>
            <td>19¼<br />
            488.95</td>
            </tr>
            <tr>
            <td>24</td>
            <td>5.3/8<br />
            136.53</td>
            <td>27.7/8<br />
            708.03</td>
            <td>25.1/8<br />
            638.18</td>
            <td>23¼<br />
            590.55</td>
            </tr>
            <tr>
            <td>NPS</td>
            <td>A</td>
            <td>B</td>
            <td>C</td>
            <td>D</td>
            </tr>
            </tbody>
            </table>
            </div><br />

            <h2 className="mb-4 mt-4 font-bold text-2xl">Stainless Steel 316 Outlets Sockolet Dimensions</h2> 
            <div className="overflow-x-auto">
            <table>
            <thead>
            <tr>
            <th rowSpan="2">Nominal Pipe Size</th>
            <th rowSpan="2">Socket (C)<br />
            Minimum</th>
            <th colSpan="2">Face to Crotch (L)</th>
            </tr>
            <tr>
            <th>3000 Lbs</th>
            <th>6000 Lbs</th>
            </tr>
            <tr>
            <td>1″</td>
            <td>13</td>
            <td>22</td>
            <td>29</td>
            </tr>
            </thead>
            <tbody>
            <tr>
            <td>1/8″</td>
            <td>10</td>
            <td>10</td>
            <td>–</td>
            </tr>
            <tr>
            <td>1/4″</td>
            <td>10</td>
            <td>10</td>
            <td>–</td>
            </tr>
            <tr>
            <td>3/8″</td>
            <td>10</td>
            <td>13</td>
            <td>–</td>
            </tr>
            <tr>
            <td>1/2″</td>
            <td>10</td>
            <td>16</td>
            <td>24</td>
            </tr>
            <tr>
            <td>3/4″</td>
            <td>13</td>
            <td>16</td>
            <td>25</td>
            </tr>
            <tr>
            <td>1 1/4″</td>
            <td>13</td>
            <td>22</td>
            <td>30</td>
            </tr>
            <tr>
            <td>1 1/2″</td>
            <td>13</td>
            <td>24</td>
            <td>32</td>
            </tr>
            <tr>
            <td>2″</td>
            <td>16</td>
            <td>24</td>
            <td>37</td>
            </tr>
            <tr>
            <td>2 1/2″</td>
            <td>16</td>
            <td>25</td>
            <td>–</td>
            </tr>
            <tr>
            <td>3″</td>
            <td>16</td>
            <td>30</td>
            <td>–</td>
            </tr>
            <tr>
            <td>4″</td>
            <td>19</td>
            <td>30</td>
            <td>–</td>
            </tr>
            </tbody>
            </table>
            </div><br />

            <h2 className="mb-4 mt-4 font-bold text-2xl">Stainless Steel 316 Outlets Threadolet Dimensions</h2> 
            <div className="overflow-x-auto">
            <table>
            <thead>
            <tr>
            <th rowSpan="2">Nominal Pipe Size</th>
            <th colSpan="2">Face to Crotch <em>(L)</em></th>
            </tr>
            <tr>
            <th>3000 Lbs</th>
            <th>6000 Lbs</th>
            </tr>
            <tr>
            <td>1″</td>
            <td>33</td>
            <td>40</td>
            </tr>
            </thead>
            <tbody>
            <tr>
            <td>1/8″</td>
            <td>19</td>
            <td>–</td>
            </tr>
            <tr>
            <td>1/4″</td>
            <td>19</td>
            <td>–</td>
            </tr>
            <tr>
            <td>3/8″</td>
            <td>21</td>
            <td>–</td>
            </tr>
            <tr>
            <td>1/2″</td>
            <td>25</td>
            <td>32</td>
            </tr>
            <tr>
            <td>3/4″</td>
            <td>27</td>
            <td>37</td>
            </tr>
            <tr>
            <td>1 1/4″</td>
            <td>33</td>
            <td>41</td>
            </tr>
            <tr>
            <td>1 1/2″</td>
            <td>35</td>
            <td>43</td>
            </tr>
            <tr>
            <td>2″</td>
            <td>38</td>
            <td>52</td>
            </tr>
            <tr>
            <td>2 1/2″</td>
            <td>46</td>
            <td>–</td>
            </tr>
            <tr>
            <td>3″</td>
            <td>51</td>
            <td>–</td>
            </tr>
            <tr>
            <td>4″</td>
            <td>57</td>
            <td>–</td>
            </tr>
            </tbody>
            </table>
            </div><br />
              {/* chemical composition */}
              {/* mechnical property */}
              {/* weight chart */}
              {/* dimensionchart */}

              <div>
                <h3 className="text-xl font-semibold mb-4">Applications:</h3>

                <AnimatedBars />
              </div>
            </div>
          </div>
          {/* ------------ */}
          <div className="lg:w-1/4 mt-8 lg:mt-0 hidden sm:block">
            <img
              src={ssoutlets}
              alt="Anchor Bolts"
              className="w-full h-auto mb-6 rounded shadow-lg"
            />
            <MaterialGrade />
            <RelatedProducts />
          </div>
        </div>
      </div>
    </div>
  </Layout>
  )
}

export default StainlessSteel316Outlets