import React from "react";
import Layout from "../../common/Layout";
import RelatedProducts from "../../ProductComponent/RelatedProducts";
import MaterialGrade from "../MaterialGrade.jsx";
import alloyFastner from "../../../assets/allProductsImages/alloyfastner.webp"
import AnimatedBars from "../AnimatedBars.jsx";

const ASTMA193GRB7MBolts = () => {
  return (
    <Layout>
      <div>
        <div className="text-fontDefault p-4 prod">
          <div className="customContainer  text-fontDefault p-6 shadow-md rounded-md flex flex-col lg:flex-row">
            <div className="lg:w-3/4 lg:pr-2">
              <h1 className="text-xl md:text-3xl font-bold mb-4 ">
                Grade B7M Hex Bolt, Ready Stock Of Alloy Steel A193 B7M Allen
                Cap Bolt, Manufacturer of B7M Threaded Rod, Alloy Steel ASTM
                A193 B7M High Temperature Fasteners, AS B7M Socket Head Cap
                Screw
              </h1>

              <div className="lg:w-1/4 mt-8 lg:mt-0 sm:block md:hidden">
                <img
                  src={alloyFastner}
                  alt="Anchor Bolts"
                  className="w-full h-auto mb-6 rounded shadow-lg"
                />
              </div>

              <div className="mb-6">
                <p className="mb-2 text-justify">
                  Hariom Steel is a well-known supplier of ASTM A193 Grade B7M
                  Bolts, which has a significant impact on the physical and
                  molecular properties.
                </p>
                <p className="mb-2 text-justify">
                  At relatively elevated temperatures, this Alloy Steel ASME
                  SA193 Grade B7 MU-Bolts maintains its strength well, has
                  excellent cold and hot working properties, and is readily
                  wieldable. The impedance of these products rose in the high
                  frequency area in the presence of 1 pap sulphide, and the
                  frequency dependence of the phase angle revealed two time
                  constants. When this alloy was first presented, it
                  demonstrated corrosion protection comparable to copper, as
                  well as greater overall strength and fatigue strength.
                  Ferrules, plumbing fittings, power steering tubes, and salt
                  water pipeline systems all use it. It provides the necessary
                  resilience to saltwater rust, erosion, impingement, and
                  bacterial fouling.
                </p>

                <div className="mt-5 mb-5  flex items-center justify-center">
                  <img src={alloyFastner} alt="" />
                </div>

                <h2 className="mb-4 mt-4 font-bold text-2xl">
                  {" "}
                  ASTM A193 GR.B7M Bolts Specifications
                </h2>
                <div className="overflow-x-auto">
                  <table>
                    <tbody>
                      <tr>
                        <th>
                          <div>
                            <strong>Dimensions</strong>
                          </div>
                        </th>
                        <td>
                          <div>ASME B18.2.1, B18.3</div>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <div>
                            <strong>Melt Origin</strong>
                          </div>
                        </th>
                        <td>
                          <div>South America/East Europe/JapanKorea/</div>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <div>
                            <strong>Product Origin</strong>
                          </div>
                        </th>
                        <td>
                          <div>Made in India</div>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <div>
                            <strong>Threads Configuration</strong>
                          </div>
                        </th>
                        <td>
                          <div>
                            ASME B1.1 2A/3A Inch Unified Threads and B1.13M 6h
                            Metric Coarse Threads
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <div>
                            <strong>Certification</strong>
                          </div>
                        </th>
                        <td>
                          <div>Dual Certified As per ASTM &amp; ASME Codes</div>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <div>
                            <strong>Additional Certifications</strong>
                          </div>
                        </th>
                        <td>
                          <div>
                            PED 97/23/EC Certified, Merkblatt AD 2000 W2
                            Certified
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <div>
                            <strong>Mandatory Requirements</strong>
                          </div>
                        </th>
                        <td>
                          <div>
                            Free from Mercury &amp; Radioactive Contamination
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <div>
                            <strong>Additional Requirements</strong>
                          </div>
                        </th>
                        <td>
                          <div>NACE MR0103 / MR0175 / ISO 15156 Compliance</div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                

                <h2 className="mb-4 mt-4 font-bold text-2xl">
                  ASTM A193 GR.B7M Bolts Chemical Composition
                </h2>
                <div className="overflow-x-auto">
                  <table>
                    <thead>
                      <tr>
                        <th rowSpan="3">Element</th>
                        <th>Alloy Steel</th>
                      </tr>
                      <tr>
                        <th>
                          Chromium Molybdenum Steel<sup>A</sup>
                        </th>
                      </tr>
                      <tr>
                        <th>
                          Composition<sup>B</sup>, %
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Carbon</td>
                        <td>
                          0.37 - 0.49<sup>C</sup>
                        </td>
                      </tr>
                      <tr>
                        <td>Manganese</td>
                        <td>0.65 - 1.10</td>
                      </tr>
                      <tr>
                        <td>Phosphorus</td>
                        <td>0.035 max</td>
                      </tr>
                      <tr>
                        <td>Sulfur</td>
                        <td>0.040 max</td>
                      </tr>
                      <tr>
                        <td>Silicon</td>
                        <td>0.15 - 0.35</td>
                      </tr>
                      <tr>
                        <td>Chromium</td>
                        <td>0.75 - 1.20</td>
                      </tr>
                      <tr>
                        <td>Molybdenum</td>
                        <td>0.15 - 0.25</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <h2 className="mb-4 mt-4 font-bold text-2xl">ASTM A193 GR.B7M Bolts Mechanical Properties</h2>
                        <div className="overflow-x-auto">
                        <table>
                        <thead>
                        <tr>
                        <th scope="col" rowSpan="3">Diameter, inch.</th>
                        <th scope="col" rowSpan="3">Tensile Strength,<br/>min, ksi</th>
                        <th scope="col" rowSpan="3">Yield Strength,<br/>min, 0.2% offset,<br/>ksi</th>
                        <th scope="col" rowSpan="3">Elongation<br/>in 4D,<br/>min, %</th>
                        <th scope="col" rowSpan="3">Reduction<br/>of Area,<br/>min, %</th>
                        <th scope="col" rowSpan="2">Hardness,<br/>max</th>
                        </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td data-title="Diameter">4 and under</td>  
                            <td data-title="Tensile Strength">100</td>
                            <td data-title="Yield Strength">80</td>
                            <td data-title="Elongation">18</td>
                            <td data-title="Reduction">50</td>
                            <td data-title="Hardness">235 HBW or 99 HRB</td>
                          </tr>
                          <tr>
                            <td data-title="Diameter">over 4 to 7, inclusive</td>   
                            <td data-title="Tensile Strength">100</td>
                            <td data-title="Yield Strength">75</td>
                            <td data-title="Elongation">18</td>
                            <td data-title="Reduction">50</td>
                            <td data-title="Hardness">235 HBW or 99 HRB</td>
                          </tr>
                        </tbody>
                        </table>
                        </div>

                        <h2 className="mb-4 mt-4 font-bold text-2xl">Weight Chart Of ASTM A193 GR.B7M Bolts</h2> 
            <div className="overflow-x-auto">
            <table>
                <thead>
                <tr>
                      <th colSpan="11"><strong>MM THREADS AS PER IS 1363/67</strong></th>
                    </tr>
                    <tr>
                      <th colSpan="11"><em><strong>APPROXIMATE WEIGHT IN KGS FOR 100 NOS</strong></em></th>
                    </tr>
                    <tr>
                      <th rowSpan="2"><strong>LENGTH</strong></th>
                      <th colSpan="10"><strong>DIAMETER</strong></th>
                    </tr>
                    <tr>
                      <th><strong>M 5</strong></th>
                      <th><strong>M 6</strong></th>
                      <th><strong>M 8</strong></th>
                      <th><strong>M 10</strong></th>
                      <th><strong>M 12</strong></th>
                      <th><strong>M 14</strong></th>
                      <th><strong>M 16</strong></th>
                      <th><strong>M 18</strong></th>
                      <th><strong>M 20</strong></th>
                      <th><strong>M 22</strong></th>
                    </tr>
                </thead>
                  <tbody>
                    
                    <tr>
                      <td><strong>16 mm</strong></td>
                      <td>0.448</td>
                      <td>0.743</td>
                      <td>1.590</td>
                      <td>3.211</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><strong>20 mm</strong></td>
                      <td>0.598</td>
                      <td>0.812</td>
                      <td>1.711</td>
                      <td>3.411</td>
                      <td>4.892</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><strong>25 mm</strong></td>
                      <td>0.660</td>
                      <td>0.897</td>
                      <td>1.871</td>
                      <td>3.660</td>
                      <td>5.252</td>
                      <td>8.170</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><strong>30 mm</strong></td>
                      <td>0.737</td>
                      <td>1.010</td>
                      <td>2.070</td>
                      <td>3.912</td>
                      <td>5.574</td>
                      <td>9.690</td>
                      <td>10.707</td>
                      <td></td>
                      <td>18.657</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><strong>35 mm</strong></td>
                      <td>0.804</td>
                      <td>1.120</td>
                      <td>2.271</td>
                      <td>4.223</td>
                      <td>5.974</td>
                      <td>10.000</td>
                      <td>11.416</td>
                      <td>16.026</td>
                      <td>19.608</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><strong>40 mm</strong></td>
                      <td>0.881</td>
                      <td>1.230</td>
                      <td>2.470</td>
                      <td>4.533</td>
                      <td>6.410</td>
                      <td>10.288</td>
                      <td>12.107</td>
                      <td>16.667</td>
                      <td>20.747</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><strong>45 mm</strong></td>
                      <td>0.958</td>
                      <td>1.340</td>
                      <td>2.671</td>
                      <td>4.840</td>
                      <td>6.859</td>
                      <td>10.504</td>
                      <td>12.821</td>
                      <td>17.241</td>
                      <td>21.739</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><strong>50 mm</strong></td>
                      <td>1.030</td>
                      <td>1.450</td>
                      <td>2.870</td>
                      <td>5.155</td>
                      <td>7.299</td>
                      <td>11.261</td>
                      <td>13.624</td>
                      <td>17.857</td>
                      <td>22.727</td>
                      <td>28.409</td>
                    </tr>
                    <tr>
                      <td><strong>55 mm</strong></td>
                      <td></td>
                      <td>1.560</td>
                      <td>3.071</td>
                      <td>5.464</td>
                      <td>7.740</td>
                      <td>11.628</td>
                      <td>14.409</td>
                      <td>18.868</td>
                      <td>23.810</td>
                      <td>30.120</td>
                    </tr>
                    <tr>
                      <td><strong>60 mm</strong></td>
                      <td></td>
                      <td>1.670</td>
                      <td>3.270</td>
                      <td>5.774</td>
                      <td>8.183</td>
                      <td>12.255</td>
                      <td>15.244</td>
                      <td>20.000</td>
                      <td>25.126</td>
                      <td>32.051</td>
                    </tr>
                    <tr>
                      <td><strong>65 mm</strong></td>
                      <td></td>
                      <td>1.781</td>
                      <td>3.472</td>
                      <td>6.083</td>
                      <td>8.621</td>
                      <td>12.626</td>
                      <td>16.026</td>
                      <td>21.008</td>
                      <td>26.455</td>
                      <td>34.014</td>
                    </tr>
                    <tr>
                      <td><strong>70 mm</strong></td>
                      <td></td>
                      <td>1.890</td>
                      <td>3.671</td>
                      <td>6.394</td>
                      <td>9.058</td>
                      <td>12.987</td>
                      <td>16.835</td>
                      <td>22.124</td>
                      <td>27.778</td>
                      <td>35.461</td>
                    </tr>
                    <tr>
                      <td><strong>75 mm</strong></td>
                      <td></td>
                      <td>2.000</td>
                      <td>3.873</td>
                      <td>6.702</td>
                      <td>9.506</td>
                      <td>13.228</td>
                      <td>17.606</td>
                      <td>23.256</td>
                      <td>29.070</td>
                      <td>39.063</td>
                    </tr>
                    <tr>
                      <td><strong>80 mm</strong></td>
                      <td></td>
                      <td>2.111</td>
                      <td>4.072</td>
                      <td>7.013</td>
                      <td>10.000</td>
                      <td>13.889</td>
                      <td>18.248</td>
                      <td>24.510</td>
                      <td>30.303</td>
                      <td>40.000</td>
                    </tr>
                    <tr>
                      <td><strong>90 mm</strong></td>
                      <td></td>
                      <td></td>
                      <td>4.472</td>
                      <td>7.634</td>
                      <td>10.917</td>
                      <td>14.925</td>
                      <td>19.841</td>
                      <td>26.042</td>
                      <td>33.113</td>
                      <td>41.667</td>
                    </tr>
                    <tr>
                      <td><strong>100 mm</strong></td>
                      <td></td>
                      <td></td>
                      <td>4.873</td>
                      <td>8.251</td>
                      <td>11.820</td>
                      <td>16.340</td>
                      <td>21.459</td>
                      <td>27.778</td>
                      <td>35.714</td>
                      <td>46.296</td>
                    </tr>
                    <tr>
                      <td><strong>110 mm</strong></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>8.881</td>
                      <td>12.723</td>
                      <td>17.730</td>
                      <td>23.041</td>
                      <td>31.250</td>
                      <td>38.168</td>
                      <td>47.170</td>
                    </tr>
                    <tr>
                      <td><strong>120 mm</strong></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>9.506</td>
                      <td>13.624</td>
                      <td>18.797</td>
                      <td>24.631</td>
                      <td>32.895</td>
                      <td>40.984</td>
                      <td>50.000</td>
                    </tr>
                    <tr>
                      <td><strong>130 mm</strong></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>10.101</td>
                      <td>14.535</td>
                      <td>20.000</td>
                      <td>26.316</td>
                      <td>34.722</td>
                      <td>43.478</td>
                      <td>52.632</td>
                    </tr>
                    <tr>
                      <td><strong>140 mm</strong></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>10.707</td>
                      <td>15.432</td>
                      <td>20.833</td>
                      <td>27.933</td>
                      <td>35.461</td>
                      <td>46.296</td>
                      <td>55.556</td>
                    </tr>
                    <tr>
                      <td><strong>150 mm</strong></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>11.312</td>
                      <td>16.340</td>
                      <td>22.222</td>
                      <td>29.412</td>
                      <td>37.594</td>
                      <td>48.544</td>
                      <td>58.824</td>
                    </tr>
                    <tr>
                      <td><strong>160 mm</strong></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>11.905</td>
                      <td>17.241</td>
                      <td>24.272</td>
                      <td>31.056</td>
                      <td>40.984</td>
                      <td>50.505</td>
                      <td>61.728</td>
                    </tr>
                    <tr>
                      <td><strong>170 mm</strong></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>12.500</td>
                      <td>18.116</td>
                      <td></td>
                      <td>32.680</td>
                      <td></td>
                      <td>53.191</td>
                      <td>64.935</td>
                    </tr>
                    <tr>
                      <td><strong>180 mm</strong></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>13.123</td>
                      <td>19.011</td>
                      <td>26.882</td>
                      <td>34.247</td>
                      <td></td>
                      <td>55.556</td>
                      <td>67.568</td>
                    </tr>
                    <tr>
                      <td><strong>190 mm</strong></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>13.736</td>
                      <td>19.920</td>
                      <td></td>
                      <td>35.971</td>
                      <td></td>
                      <td>58.140</td>
                      <td>70.423</td>
                    </tr>
                    <tr>
                      <td><strong>200 mm</strong></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>14.327</td>
                      <td>20.833</td>
                      <td>29.586</td>
                      <td>37.594</td>
                      <td>49.020</td>
                      <td>60.976</td>
                      <td>73.529</td>
                    </tr>
                    <tr>
                      <td><strong>220 mm</strong></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>15.528</td>
                      <td>22.624</td>
                      <td></td>
                      <td>40.650</td>
                      <td></td>
                      <td>65.789</td>
                      <td>79.365</td>
                    </tr>
                    <tr>
                      <td><strong>240 mm</strong></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>16.722</td>
                      <td>24.510</td>
                      <td></td>
                      <td>43.860</td>
                      <td></td>
                      <td>71.429</td>
                      <td>84.746</td>
                    </tr>
                    <tr>
                      <td><strong>250 mm</strong></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>17.301</td>
                      <td>25.381</td>
                      <td></td>
                      <td>45.455</td>
                      <td></td>
                      <td>74.627</td>
                      <td>87.719</td>
                    </tr>
                    <tr>
                      <td><strong>260 mm</strong></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>17.921</td>
                      <td>26.316</td>
                      <td></td>
                      <td>47.170</td>
                      <td></td>
                      <td>76.923</td>
                      <td>90.909</td>
                    </tr>
                    <tr>
                      <td><strong>280 mm</strong></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>19.157</td>
                      <td>28.090</td>
                      <td></td>
                      <td>50.505</td>
                      <td></td>
                      <td>81.967</td>
                      <td>98.039</td>
                    </tr>
                    <tr>
                      <td><strong>300 mm</strong></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>20.325</td>
                      <td>29.940</td>
                      <td></td>
                      <td>53.763</td>
                      <td></td>
                      <td>87.719</td>
                      <td>104.167</td>
                    </tr>
                    <tr>
                      <td><strong>320 mm</strong></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>31.646</td>
                      <td></td>
                      <td>56.818</td>
                      <td></td>
                      <td>92.593</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><strong>340 mm</strong></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>33.557</td>
                      <td></td>
                      <td>60.241</td>
                      <td></td>
                      <td>98.039</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><strong>360 mm</strong></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>35.211</td>
                      <td></td>
                      <td>63.291</td>
                      <td></td>
                      <td>104.167</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><strong>380 mm</strong></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>37.037</td>
                      <td></td>
                      <td>66.667</td>
                      <td></td>
                      <td>108.696</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><strong>400 mm</strong></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>39.063</td>
                      <td></td>
                      <td>69.444</td>
                      <td></td>
                      <td>113.636</td>
                      <td></td>
                    </tr>
                  </tbody>
              </table>
            </div>
            <h2 className="mb-4 mt-4 font-bold text-2xl">Dimensions Of ASTM A193 B7M Bolts</h2> 
            <div className="overflow-x-auto">
            <table>
            <thead>
              <tr>
              <th colSpan="9">**All measurements in millimeters**</th>
              </tr>
                <tr>
                  <th>NOMINAL SIZE (D)</th>
                  <th>BODY DIAMETER</th>
                  <th></th>
                  <th>HEAD THICKNEASTM A193</th>
                  <th></th>
                  <th>ACROASTM A193 THE FLATS</th>
                  <th></th>
                  <th>ACROASTM A193 CORNERS</th>
                  <th></th>
                </tr>
                <tr>
                  <th></th>
                  <th>D (MAX)</th>
                  <th>D (MIN)</th>
                  <th>H (MAX)</th>
                  <th>H (MIN)</th>
                  <th>F (MAX)</th>
                  <th>F (MIN)</th>
                  <th>C (MAX)</th>
                  <th>C (MIN)</th>
                </tr>
                </thead>
              <tbody>
                <tr>
                  <td>m10</td>
                  <td>10.00</td>
                  <td>9.78</td>
                  <td>6.63</td>
                  <td>6.17</td>
                  <td>17.00</td>
                  <td>15.73</td>
                  <td>18.48</td>
                  <td>17.77</td>
                </tr>
                <tr>
                  <td>m12</td>
                  <td>12.00</td>
                  <td>11.73</td>
                  <td>7.76</td>
                  <td>4.24</td>
                  <td>19.00</td>
                  <td>17.73</td>
                  <td>20.78</td>
                  <td>20.03</td>
                </tr>
                <tr>
                  <td>m14</td>
                  <td>14.00</td>
                  <td>13.73</td>
                  <td>9.09</td>
                  <td>8.51</td>
                  <td>22.00</td>
                  <td>20.67</td>
                  <td>24.25</td>
                  <td>23.35</td>
                </tr>
                <tr>
                  <td>m16</td>
                  <td>16.00</td>
                  <td>15.73</td>
                  <td>10.32</td>
                  <td>9.68</td>
                  <td>24.00</td>
                  <td>23.67</td>
                  <td>27.71</td>
                  <td>26.75</td>
                </tr>
                <tr>
                  <td>m20</td>
                  <td>20.00</td>
                  <td>19.67</td>
                  <td>12.88</td>
                  <td>12.12</td>
                  <td>30.00</td>
                  <td>29.16</td>
                  <td>34.64</td>
                  <td>32.95</td>
                </tr>
                <tr>
                  <td>m24</td>
                  <td>24.00</td>
                  <td>23.67</td>
                  <td>15.44</td>
                  <td>14.56</td>
                  <td>36.00</td>
                  <td>35.00</td>
                  <td>41.57</td>
                  <td>39.55</td>
                </tr>
                <tr>
                  <td>m30</td>
                  <td>30.00</td>
                  <td>29.67</td>
                  <td>19.48</td>
                  <td>17.92</td>
                  <td>46.00</td>
                  <td>45.00</td>
                  <td>53.12</td>
                  <td>50.85</td>
                </tr>
                <tr>
                  <td>m36</td>
                  <td>36.00</td>
                  <td>35.61</td>
                  <td>23.38</td>
                  <td>21.63</td>
                  <td>55.00</td>
                  <td>53.80</td>
                  <td>63.51</td>
                  <td>60.79</td>
                </tr>
                <tr>
                  <td>m42</td>
                  <td>42.00</td>
                  <td>41.38</td>
                  <td>26.97</td>
                  <td>25.03</td>
                  <td>65.00</td>
                  <td>62.90</td>
                  <td>75.06</td>
                  <td>71.71</td>
                </tr>
                <tr>
                  <td>m48</td>
                  <td>48.00</td>
                  <td>47.38</td>
                  <td>31.07</td>
                  <td>28.93</td>
                  <td>75.00</td>
                  <td>72.60</td>
                  <td>86.60</td>
                  <td>82.76</td>
                </tr>
                <tr>
                  <td>m56</td>
                  <td>56.00</td>
                  <td>55.26</td>
                  <td>36.2</td>
                  <td>33.80</td>
                  <td>85.00</td>
                  <td>82.20</td>
                  <td>98.15</td>
                  <td>93.71</td>
                </tr>
                <tr>
                  <td>m64</td>
                  <td>64.00</td>
                  <td>63.26</td>
                  <td>41.32</td>
                  <td>38.68</td>
                  <td>95.00</td>
                  <td>91.80</td>
                  <td>109.70</td>
                  <td>104.65</td>
                </tr>
                <tr>
                  <td>m72</td>
                  <td>72.00</td>
                  <td>71.26</td>
                  <td>46.45</td>
                  <td>43.55</td>
                  <td>105.00</td>
                  <td>101.40</td>
                  <td>121.24</td>
                  <td>115.60</td>
                </tr>
                <tr>
                  <td>m80</td>
                  <td>80.00</td>
                  <td>79.26</td>
                  <td>51.58</td>
                  <td>48.42</td>
                  <td>115.00</td>
                  <td>111.00</td>
                  <td>132.72</td>
                  <td>126.54</td>
                </tr>
                <tr>
                  <td>m90</td>
                  <td>90.00</td>
                  <td>89.13</td>
                  <td>57.74</td>
                  <td>54.26</td>
                  <td>130.00</td>
                  <td>125.50</td>
                  <td>150.11</td>
                  <td>143.07</td>
                </tr>
                <tr>
                  <td>m100</td>
                  <td>90.00</td>
                  <td>99.13</td>
                  <td>63.9</td>
                  <td>60.10</td>
                  <td>145.00</td>
                  <td>140.00</td>
                  <td>167.43</td>
                  <td>159.60</td>
                </tr>
            </tbody>
        
            </table>
            </div>
            <br />

            <div className="mb-8">
    <h3 className="text-xl font-semibold mb-4">
       Applications:
    </h3>
    <AnimatedBars />
    
  </div>
              </div>
            </div>
{/* ------------ */}
            <div className="lg:w-1/4 mt-8 lg:mt-0 hidden sm:block">
              <img
                src={alloyFastner}
                alt="Anchor Bolts"
                className="w-full h-auto mb-6 rounded shadow-lg"
              />
              <MaterialGrade />
              <RelatedProducts />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ASTMA193GRB7MBolts;
