import React from "react";
import Layout from "../../common/Layout";
import weldNeckFlanges from "../../../assets/allProductsImages/weld-neck-flange.webp";
import RelatedProducts from "../../ProductComponent/RelatedProducts";

const WeldNeckFlanges = () => {
  return (
    <Layout>
      <div>
        <div className="text-fontDefault p-4 prod">
          <div className="customContainer text-fontDefault p-6 shadow-md rounded-md flex flex-col lg:flex-row">
            <div className="lg:w-3/4 lg:pr-6">
              <h1 className="text-2xl text-center md:text-left font-bold mb-4">
                Weld Neck Flanges
              </h1>
              <div className="lg:w-1/4 mt-8 lg:mt-0 sm:block md:hidden">
                <img
                  src={weldNeckFlanges}
                  alt="Hex Nuts"
                  className="w-full h-auto mb-6 rounded shadow-lg"
                />
              </div>
              <div className="mb-6">
                <p className="mb-2">
                  Hariom Steel is a distinguished supplier of Weld Neck Pipe
                  Flanges, which can be welded using any conventional technique.
                  Its appropriate heated work range is 2000 degrees Fahrenheit
                  to 2200 degrees Fahrenheit (1093 degrees Celsius to 1204
                  degrees Celsius), but it should not be worked below 1650
                  degrees Fahrenheit (899 degrees Celsius). Super Duplex Steel
                  Weld Neck Flanges is hard enable steel that can be tempered as
                  high as 1350 degrees Fahrenheit to create high impact
                  toughness and is oxidation resistant up to 1500 degrees
                  Fahrenheit sporadically and 1200 degrees Fahrenheit
                  constantly. Temper for desired rigidity, furnace or air cool
                  to 1750°F - 1850°F (954°C - 1010°C), and oil quench for
                  maximum hardness. This steel is typically chosen based on heat
                  treating reaction, mechanical qualities, and fabricating
                  traits, depending on the customer's end-use needs.
                </p>
              </div>

              <h3 className="text-xl font-semibold mb-4">
                Weld Neck Flanges Specifications
              </h3>
              <div className="overflow-x-auto">
                <table>
                  <tbody>
                    <tr>
                      <th width="30%">Size Chart</th>

                      <td width="70%">
                        1/2" (15 NB) to 48" (1200NB) DN10~DN5000
                      </td>
                    </tr>

                    <tr>
                      <th>Standards</th>

                      <td>
                        ANSI/ASME B16.5, B16.47 Series A &amp; B, B16.48,
                        BS4504, BS 10, EN-1092, DIN, ASME Flanges, ASME Flanges,
                        BS Flanges, DIN Flanges, EN Flanges, GOST Flange,
                        ASME/ASME B16.5/16.36/16.47A/16.47B, MSS S44, ISO70051,
                        JISB2220, BS1560-3.1, API7S-15, API7S-43, API605, EN1092
                      </td>
                    </tr>

                    <tr>
                      <th>Pressure Rating ANSI</th>

                      <td>
                        Class 150 LBS, 300 LBS, 600 LBS, 900 LBS, 1500 LBS, 2500
                        LBS
                      </td>
                    </tr>

                    <tr>
                      <th>DIN</th>

                      <td>
                        6Bar 10Bar 16Bar 25Bar 40Bar / PN6 PN10 PN16 PN25 PN40,
                        PN64
                      </td>
                    </tr>

                    <tr>
                      <th>JIS</th>

                      <td>5K, 10 K, 16 K 20 K, 30 K, 40 K, 63 K</td>
                    </tr>

                    <tr>
                      <th>UNI</th>

                      <td>6Bar 10Bar 16Bar 25Bar 40Bar</td>
                    </tr>

                    <tr>
                      <th>EN</th>

                      <td>6Bar 10Bar 16Bar 25Bar 40Bar</td>
                    </tr>

                    <tr>
                      <th>Coating</th>

                      <td>
                        Oil Black Paint, Anti-rust Paint, Zinc Plated, Yellow
                        Transparent, Cold and Hot Dip Galvanized
                      </td>
                    </tr>

                    <tr>
                      <th>Most Common Types</th>

                      <td>Forged / Threaded / Screwed / Plate</td>
                    </tr>

                    <tr>
                      <th>Test Certificates</th>

                      <td>
                        EN 10204/3.1B
                        <br />
                        Raw Materials Certificate
                        <br />
                        100% Radiography Test Report
                        <br />
                        Third Party Inspection Report, etc
                      </td>
                    </tr>

                    <tr>
                      <th>Production technique</th>

                      <td>
                        <ul>
                          <li>Forged, Heat treated and machined</li>
                        </ul>
                      </td>
                    </tr>

                    <tr>
                      <th>Connect Type/ Flange Face Type</th>

                      <td>
                        Raised Face (RF), Ring Type Joint (RTJ), Flat Face (FF),
                        Large Male-Female (LMF), Lap-Joint Face (LJF), Small
                        Male-Female (SMF), Small Tongue, Large Tongue &amp;
                        Groove
                      </td>
                    </tr>

                    <tr>
                      <th>Special design</th>

                      <td>
                        <p>
                          As per your drawing
                          <br />
                          AS, ANSI, BS, DIN and JIS
                          <br />
                          15 NB (1/2") to 200 NB (8")
                          <br />
                          Equal and Reducing Configurations
                        </p>
                      </td>
                    </tr>

                    <tr>
                      <th>Test</th>

                      <td>
                        Direct-reading Spectrograph, Hydrostatic testing
                        machine, X-ray detector, UI trasonic flaw detector,
                        Magnetic particle detector
                      </td>
                    </tr>

                    <tr>
                      <th>Equipment</th>

                      <td>
                        Press machine, Bending machine, Pushing Machine,
                        electric bevelling machine, Sand-blasting machine etc
                      </td>
                    </tr>

                    <tr>
                      <th>Origin</th>

                      <td>Indian / West Europe / Japan / USA / Korean</td>
                    </tr>

                    <tr>
                      <th>Manufacturer of</th>

                      <td>
                        <p>
                          ASME DIN, GOST, JIS, UNI, BS, AS2129, AWWA, EN, SABS,
                          NFE etc.
                          <br />
                          ANSI B16.5 Class 150 Weld-Neck Flanges: -BS Flange, EN
                          Flange, API 6A Flanges, ASME Flanges, ASME Flanges,
                          DIN Flanges, EN1092-1 Flanges, UNI Flanges, JIS/ KS
                          Flanges, BS4504 Flanges, GB Flanges, AWWA C207
                          Flanges, GOST Flanges, PSI Flangse
                        </p>

                        <ul>
                          <li>B.S: BS4504 , BS3293, BS1560, BS10</li>

                          <li>AS 2129: Table D; Table E; Table H</li>

                          <li>
                            ASME B16.5, ASME B16.47 Series A &amp; B, MSS SP44,
                            ASA, API-605, AWWA, Custom Drawings
                          </li>

                          <li>
                            ASME : ASME B16.5, ASME B16.47(API605), MSS SP44,
                            ASME B16.36, ASME B16.48
                          </li>

                          <li>
                            ASME B16.5, ASME B16.47, MSS SP44, ASME B16.36, ASME
                            B16.48
                          </li>

                          <li>American standard (ANSI)</li>

                          <li>German (DIN)</li>

                          <li>British standard (BS)</li>

                          <li>
                            DIN: DIN 2527, DIN 2566, DIN 2573, DIN 2576, DIN
                            2641, DIN 2642, DIN 2655, DIN 2656, DIN 2627, DIN
                            2628, DIN 2629, DIN 2631, DIN 2632, DIN 2633, DIN
                            2634, DIN 2635, DIN 2636, DIN 2637, DIN 2638 ANSI
                            B16.5 Class 150 Weld-Neck Flanges
                          </li>

                          <li>
                            The other standard: AWWA C207; EN1092-1, GOST12820,
                            JIS B2220; KS B1503, SABS1123; NFE29203; UNI2276
                          </li>
                        </ul>
                      </td>
                    </tr>

                    <tr>
                      <th>Uses &amp; Application</th>

                      <td>
                        <ul>
                          <li>Bitumen upgraders.</li>

                          <li>Heavy oil refineries.</li>

                          <li>Nuclear power (mostly seamless).</li>

                          <li>Petrochemicals and acids.</li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <br />

              <h3 className="text-xl font-semibold mb-4">
                {" "}
                Material Grades of Weld Neck Flanges
              </h3>
              <div className="mb-8 overflow-x-auto">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <strong> Stainless Steel Weld-Neck Flanges</strong>
                      </td>

                      <td>
                        ASTM/ASME A/SA182 F304, F304L, F316, F316L, ASTM/ASME
                        A/SA351 CF8, CF3, CF8M, CF3M, DIN 1.4301, DIN 1.4306,
                        DIN 1.4401, DIN 1.4404, DIN 1.4308, DIN 1.4408, DIN
                        1.4306, DIN 1.4409
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <strong>Carbon Steel Weld-Neck Flanges</strong>
                      </td>

                      <td>
                        ASTM/ASME A/SA105 A/SA105N &amp; A/SA216-WCB, DIN
                        1.0402, DIN 1.0460, DIN 1.0619, Die Steel, ASTM A105 /
                        ASME SA105, A105N, ASTM A350 LF2 / ASME SA350, High
                        Yield CS ASTM A694 / A694 (F52 F56 F60 F65 F70 F80)
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <strong>Alloy Steel Weld-Neck Flanges</strong>
                      </td>

                      <td>ASTM A182 / ASME SA182 F5, F9, F11, F12, F22, F91</td>
                    </tr>

                    <tr>
                      <td>
                        <strong>Titanium Weld-Neck Flanges</strong>
                      </td>

                      <td>
                        ASTM B381 / ASME SB381, Titanium Gr. 1, Titanium Gr. 2,
                        Titanium Gr. 4, Titanium Gr. 5, Titanium Gr. 7<br />
                        ASTM R50250/GR.1 | R50400/GR.2 | R50550/GR.3 |
                        R50700/GR.4 | GR.6 |R52400/GR.7 | R53400/GR.12 |
                        R56320/GR.9 |R56400/GR.5
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <strong>Copper Weld-Neck Flanges</strong>
                      </td>

                      <td>
                        T1, T2, C10100, C10200, C10300, C10400, C10500, C10700,
                        C10800, C10910,C10920, TP1, TP2, C10930, C11000, C11300,
                        C11400, C11500, C11600, C12000,C12200, C12300, TU1, TU2,
                        C12500, C14200, C14420, C14500, C14510, C14520, C14530,
                        C17200, C19200, C21000, C23000, C26000, C27000, C27400,
                        C28000, C33000, C33200, C37000, C44300, C44400, C44500,
                        C60800, C63020, C68700, C70400, C70600, C70620, C71000,
                        C71500, C71520, C71640, etc
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <strong>Copper Nickel Weld-Neck Flanges</strong>
                      </td>

                      <td>
                        ASTM / ASME SB 61 / 62 / 151 / 152, Copper Nickel 90/10
                        (C70600 ), Cupro Nickel 70/30 (C71500), UNS C71640
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <strong>Inconel Weld-Neck Flanges</strong>
                      </td>

                      <td>
                        ASTM B564 / ASME SB564, Inconel 600, 601, 625, 718, 783,
                        690, x750 ANSI B16.5 Class 150 Weld-Neck Flanges
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <strong>Hastelloy Weld-Neck Flanges</strong>
                      </td>

                      <td>
                        ASTM B564 / ASME SB564, Hastelloy C276 (UNS N10276), C22
                        (UNS N06022), C4, C2000, B2, B3, X Flanges
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <strong>Monel Weld-Neck Flanges</strong>
                      </td>

                      <td>
                        ASTM B564 / ASME SB564, Monel 400 (UNS No. N04400),
                        Monel 500 (UNS No. N05500)
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <strong>Alloy 20 Weld-Neck Flanges</strong>
                      </td>

                      <td>
                        ASTM B462 / ASME SB462, Carpenter® 20 Alloy, Alloy
                        20Cb-3
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <strong>Aluminium Weld-Neck Flanges</strong>
                      </td>

                      <td>5052 /6061/ 6063 / 2017 / 7075 / etc.</td>
                    </tr>

                    <tr>
                      <td>
                        <strong>Nickel Alloy Weld-Neck Flanges</strong>
                      </td>

                      <td>
                        ASTM B564 / ASME SB564, Nickel 200, Nickel 201, Nickel
                        205, Nickel 205LC
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <strong>Duplex Steel Weld-Neck Flanges</strong>
                      </td>

                      <td>
                        S31803 / S32205 A182 Gr F51 / F52 / F53 / F54 / F55 /
                        F57 / F59 / F60 / F61
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <strong>Super Duplex Weld-Neck Flanges</strong>
                      </td>

                      <td>
                        S32750 / S32760 A182 Gr F51 / F52 / F53 / F54 / F55 /
                        F57 / F59 / F60 / F61
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <strong>Incoloy Weld-Neck Flanges</strong>
                      </td>

                      <td>
                        ASTM B564 / ASME SB564, Incoloy 800, 800H, 800HT (UNS
                        N08800), 825 (UNS N08825), 925 Flanges
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <strong>254 SMO Weld-Neck Flanges</strong>
                      </td>

                      <td>
                        ASTM A182 / ASME SA182, SMO 254/6Mo, UNS S31254, DIN
                        1.4547
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <strong>Nimonic Weld-Neck Flanges</strong>
                      </td>

                      <td>Nimonic 75, Nimonic 80A, Nimonic 90</td>
                    </tr>

                    <tr>
                      <td>
                        <strong>Brass Weld-Neck Flanges</strong>
                      </td>

                      <td>3602 / 2604 / H59 / H62 / etc.</td>
                    </tr>

                    <tr>
                      <td>
                        <strong>Other Weld-Neck Flanges material</strong>
                      </td>

                      <td>Tin bronze, Alumunum bronze, Lead bronze</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <br />
              <h2 className="text-2xl font-bold mb-4">
                Weld Neck Flanges Dimensions Chart:
              </h2>

              {/* <img src={weldNeckFlanges} alt="" /> */}
              <br />

              <div className="overflow-x-auto">
                <table>
                  <tbody>
                    <tr className="bg-primary text-white">
                      <td colSpan="11">
                        <strong>
                          ANSI/ASME/ASA B16.5 150lb/sq.in. Flanges
                        </strong>
                      </td>
                    </tr>

                    <tr>
                      <td>ø</td>

                      <td>D</td>

                      <td>b</td>

                      <td>g</td>

                      <td>m</td>

                      <td>J</td>

                      <td>h</td>

                      <td>k</td>

                      <td>Holes</td>

                      <td>l</td>

                      <td>Kg.</td>
                    </tr>

                    <tr>
                      <td>1/2"</td>

                      <td>88,9</td>

                      <td>11,1</td>

                      <td>34,9</td>

                      <td>30,2</td>

                      <td>22,3</td>

                      <td>15,9</td>

                      <td>60,3</td>

                      <td>4</td>

                      <td>15,9</td>

                      <td>0,400</td>
                    </tr>

                    <tr>
                      <td>3/4"</td>

                      <td>98,4</td>

                      <td>12,7</td>

                      <td>42,9</td>

                      <td>38,1</td>

                      <td>27,7</td>

                      <td>15,9</td>

                      <td>69,8</td>

                      <td>4</td>

                      <td>15,9</td>

                      <td>0,700</td>
                    </tr>

                    <tr>
                      <td>1"</td>

                      <td>107,9</td>

                      <td>14,3</td>

                      <td>50,8</td>

                      <td>49,2</td>

                      <td>34,5</td>

                      <td>17,5</td>

                      <td>79,4</td>

                      <td>4</td>

                      <td>15,9</td>

                      <td>0,800</td>
                    </tr>

                    <tr>
                      <td>1 1/4"</td>

                      <td>117,5</td>

                      <td>15,9</td>

                      <td>63,5</td>

                      <td>58,8</td>

                      <td>43,2</td>

                      <td>20,6</td>

                      <td>88,9</td>

                      <td>4</td>

                      <td>15,9</td>

                      <td>1,100</td>
                    </tr>

                    <tr>
                      <td>1 1/2"</td>

                      <td>127,0</td>

                      <td>17,5</td>

                      <td>73,0</td>

                      <td>65,1</td>

                      <td>49,5</td>

                      <td>22,2</td>

                      <td>98,4</td>

                      <td>4</td>

                      <td>15,9</td>

                      <td>1,400</td>
                    </tr>

                    <tr>
                      <td>2"</td>

                      <td>152,4</td>

                      <td>19,0</td>

                      <td>92,1</td>

                      <td>77,8</td>

                      <td>62,0</td>

                      <td>25,4</td>

                      <td>120,6</td>

                      <td>4</td>

                      <td>19,0</td>

                      <td>2,200</td>
                    </tr>

                    <tr>
                      <td>2 1/2"</td>

                      <td>177,8</td>

                      <td>22,2</td>

                      <td>104,8</td>

                      <td>90,5</td>

                      <td>74,7</td>

                      <td>28,6</td>

                      <td>139,7</td>

                      <td>4</td>

                      <td>19,0</td>

                      <td>3,600</td>
                    </tr>

                    <tr>
                      <td>3"</td>

                      <td>190,5</td>

                      <td>23,8</td>

                      <td>127,0</td>

                      <td>107,9</td>

                      <td>90,7</td>

                      <td>30,2</td>

                      <td>152,4</td>

                      <td>4</td>

                      <td>19,0</td>

                      <td>4,100</td>
                    </tr>

                    <tr>
                      <td>3 1/2"</td>

                      <td>215,9</td>

                      <td>23,8</td>

                      <td>139,7</td>

                      <td>122,2</td>

                      <td>103,4</td>

                      <td>31,7</td>

                      <td>177,8</td>

                      <td>8</td>

                      <td>19,0</td>

                      <td>5,200</td>
                    </tr>

                    <tr>
                      <td>4"</td>

                      <td>228,6</td>

                      <td>23,8</td>

                      <td>157,2</td>

                      <td>134,9</td>

                      <td>116,1</td>

                      <td>33,3</td>

                      <td>190,5</td>

                      <td>8</td>

                      <td>19,0</td>

                      <td>5,600</td>
                    </tr>

                    <tr>
                      <td>5"</td>

                      <td>254,0</td>

                      <td>23,8</td>

                      <td>185,7</td>

                      <td>163,6</td>

                      <td>143,8</td>

                      <td>36,5</td>

                      <td>215,9</td>

                      <td>8</td>

                      <td>22,2</td>

                      <td>6,300</td>
                    </tr>

                    <tr>
                      <td>6"</td>

                      <td>279,4</td>

                      <td>25,4</td>

                      <td>215,9</td>

                      <td>192,1</td>

                      <td>170,7</td>

                      <td>39,7</td>

                      <td>241,3</td>

                      <td>8</td>

                      <td>22,2</td>

                      <td>7,500</td>
                    </tr>

                    <tr>
                      <td>8"</td>

                      <td>342,9</td>

                      <td>28,6</td>

                      <td>269,9</td>

                      <td>246,1</td>

                      <td>221,5</td>

                      <td>44,4</td>

                      <td>298,4</td>

                      <td>8</td>

                      <td>22,2</td>

                      <td>12,600</td>
                    </tr>

                    <tr>
                      <td>10"</td>

                      <td>406,4</td>

                      <td>30,2</td>

                      <td>323,8</td>

                      <td>304,8</td>

                      <td>276,3</td>

                      <td>49,2</td>

                      <td>361,9</td>

                      <td>12</td>

                      <td>25,4</td>

                      <td>18,500</td>
                    </tr>

                    <tr>
                      <td>12"</td>

                      <td>482,6</td>

                      <td>31,7</td>

                      <td>381,0</td>

                      <td>365,1</td>

                      <td>327,1</td>

                      <td>55,6</td>

                      <td>431,8</td>

                      <td>12</td>

                      <td>25,5</td>

                      <td>28,000</td>
                    </tr>

                    <tr>
                      <td>14"</td>

                      <td>533,4</td>

                      <td>34,9</td>

                      <td>412,7</td>

                      <td>400,0</td>

                      <td>359,1</td>

                      <td>57,1</td>

                      <td>476,2</td>

                      <td>12</td>

                      <td>28,6</td>

                      <td>36,000</td>
                    </tr>

                    <tr>
                      <td>16"</td>

                      <td>596,9</td>

                      <td>36,5</td>

                      <td>469,9</td>

                      <td>457,2</td>

                      <td>410,5</td>

                      <td>63,5</td>

                      <td>539,7</td>

                      <td>16</td>

                      <td>28,6</td>

                      <td>46,000</td>
                    </tr>

                    <tr>
                      <td>18"</td>

                      <td>635,0</td>

                      <td>39,7</td>

                      <td>533,4</td>

                      <td>504,8</td>

                      <td>461,8</td>

                      <td>68,3</td>

                      <td>577,8</td>

                      <td>16</td>

                      <td>31,7</td>

                      <td>50,000</td>
                    </tr>

                    <tr>
                      <td>20"</td>

                      <td>698,5</td>

                      <td>42,9</td>

                      <td>584,2</td>

                      <td>558,8</td>

                      <td>513,1</td>

                      <td>73,0</td>

                      <td>635,0</td>

                      <td>20</td>

                      <td>31,7</td>

                      <td>64,000</td>
                    </tr>

                    <tr>
                      <td>22"</td>

                      <td>749,3</td>

                      <td>46,0</td>

                      <td>641,2</td>

                      <td>609,6</td>

                      <td>564,4</td>

                      <td>79,4</td>

                      <td>692,1</td>

                      <td>20</td>

                      <td>34,9</td>

                      <td>72,000</td>
                    </tr>

                    <tr>
                      <td>24"</td>

                      <td>812,8</td>

                      <td>47,6</td>

                      <td>692,1</td>

                      <td>663,6</td>

                      <td>615,9</td>

                      <td>82,5</td>

                      <td>749,3</td>

                      <td>20</td>

                      <td>34,9</td>

                      <td>89,000</td>
                    </tr>
                  </tbody>

                  <tbody>
                    <tr>
                      <td colSpan="11" className="bg-primary text-white">
                        <strong>
                          ANSI/ASME/ASA B16.5 600lb/sq.in. Flanges
                        </strong>
                      </td>
                    </tr>

                    <tr>
                      <td>ø</td>

                      <td>D</td>

                      <td>b</td>

                      <td>g</td>

                      <td>m</td>

                      <td>J</td>

                      <td>h</td>

                      <td>k</td>

                      <td>Holes</td>

                      <td>l</td>

                      <td>Kg.</td>
                    </tr>

                    <tr>
                      <td>1/2"</td>

                      <td>95,2</td>

                      <td>14,3</td>

                      <td>34,9</td>

                      <td>38,1</td>

                      <td>22,3</td>

                      <td>22,2</td>

                      <td>66,7</td>

                      <td>4</td>

                      <td>15,9</td>

                      <td>0,800</td>
                    </tr>

                    <tr>
                      <td>3/4"</td>

                      <td>117,5</td>

                      <td>15,9</td>

                      <td>42,9</td>

                      <td>47,6</td>

                      <td>27,7</td>

                      <td>25,4</td>

                      <td>82,5</td>

                      <td>4</td>

                      <td>19,0</td>

                      <td>1,400</td>
                    </tr>

                    <tr>
                      <td>1"</td>

                      <td>123,8</td>

                      <td>17,5</td>

                      <td>50,8</td>

                      <td>54,0</td>

                      <td>34,5</td>

                      <td>27,0</td>

                      <td>88,9</td>

                      <td>4</td>

                      <td>19,0</td>

                      <td>1,600</td>
                    </tr>

                    <tr>
                      <td>1 1/4"</td>

                      <td>133,3</td>

                      <td>20,6</td>

                      <td>63,5</td>

                      <td>63,5</td>

                      <td>43,2</td>

                      <td>28,6</td>

                      <td>98,4</td>

                      <td>4</td>

                      <td>19,0</td>

                      <td>2,100</td>
                    </tr>

                    <tr>
                      <td>1 1/2"</td>

                      <td>155,6</td>

                      <td>22,2</td>

                      <td>73,0</td>

                      <td>69,8</td>

                      <td>49,5</td>

                      <td>31,7</td>

                      <td>114,3</td>

                      <td>4</td>

                      <td>22,2</td>

                      <td>3,100</td>
                    </tr>

                    <tr>
                      <td>2"</td>

                      <td>165,1</td>

                      <td>25,4</td>

                      <td>92,1</td>

                      <td>84,1</td>

                      <td>62,0</td>

                      <td>36,5</td>

                      <td>127,0</td>

                      <td>8</td>

                      <td>19,0</td>

                      <td>3,700</td>
                    </tr>

                    <tr>
                      <td>2 1/2"</td>

                      <td>190,5</td>

                      <td>28,6</td>

                      <td>104,8</td>

                      <td>100,0</td>

                      <td>74,7</td>

                      <td>41,3</td>

                      <td>149,2</td>

                      <td>8</td>

                      <td>22,2</td>

                      <td>5,400</td>
                    </tr>

                    <tr>
                      <td>3"</td>

                      <td>209,5</td>

                      <td>31,7</td>

                      <td>127,0</td>

                      <td>117,5</td>

                      <td>90,7</td>

                      <td>46,0</td>

                      <td>168,3</td>

                      <td>8</td>

                      <td>22,2</td>

                      <td>7,300</td>
                    </tr>

                    <tr>
                      <td>3 1/2"</td>

                      <td>228,6</td>

                      <td>34,9</td>

                      <td>139,7</td>

                      <td>133,3</td>

                      <td>103,4</td>

                      <td>49,2</td>

                      <td>184,1</td>

                      <td>8</td>

                      <td>25,4</td>

                      <td>8,900</td>
                    </tr>

                    <tr>
                      <td>4"</td>

                      <td>273,0</td>

                      <td>38,1</td>

                      <td>157,2</td>

                      <td>152,4</td>

                      <td>116,1</td>

                      <td>54,0</td>

                      <td>215,9</td>

                      <td>8</td>

                      <td>25,4</td>

                      <td>11,800</td>
                    </tr>

                    <tr>
                      <td>5"</td>

                      <td>330,2</td>

                      <td>44,4</td>

                      <td>185,7</td>

                      <td>188,9</td>

                      <td>143,8</td>

                      <td>60,3</td>

                      <td>266,7</td>

                      <td>8</td>

                      <td>28,6</td>

                      <td>24,500</td>
                    </tr>

                    <tr>
                      <td>6"</td>

                      <td>355,6</td>

                      <td>47,6</td>

                      <td>215,9</td>

                      <td>222,2</td>

                      <td>170,7</td>

                      <td>66,7</td>

                      <td>292,1</td>

                      <td>12</td>

                      <td>28,6</td>

                      <td>29,500</td>
                    </tr>

                    <tr>
                      <td>8"</td>

                      <td>419,1</td>

                      <td>55,6</td>

                      <td>269,9</td>

                      <td>273,0</td>

                      <td>221,5</td>

                      <td>76,2</td>

                      <td>349,2</td>

                      <td>12</td>

                      <td>31,7</td>

                      <td>43,000</td>
                    </tr>

                    <tr>
                      <td>10"</td>

                      <td>508,0</td>

                      <td>63,5</td>

                      <td>323,8</td>

                      <td>342,8</td>

                      <td>276,3</td>

                      <td>85,7</td>

                      <td>431,8</td>

                      <td>16</td>

                      <td>34,9</td>

                      <td>70,000</td>
                    </tr>

                    <tr>
                      <td>12"</td>

                      <td>558,8</td>

                      <td>66,7</td>

                      <td>381,0</td>

                      <td>400,0</td>

                      <td>327,1</td>

                      <td>92,1</td>

                      <td>488,9</td>

                      <td>20</td>

                      <td>34,9</td>

                      <td>86,000</td>
                    </tr>

                    <tr>
                      <td>14"</td>

                      <td>603,2</td>

                      <td>69,8</td>

                      <td>412,7</td>

                      <td>431,8</td>

                      <td>359,1</td>

                      <td>93,7</td>

                      <td>527,0</td>

                      <td>20</td>

                      <td>38,1</td>

                      <td>100,000</td>
                    </tr>

                    <tr>
                      <td>16"</td>

                      <td>685,8</td>

                      <td>76,2</td>

                      <td>469,9</td>

                      <td>495,3</td>

                      <td>410,5</td>

                      <td>106,4</td>

                      <td>603,2</td>

                      <td>20</td>

                      <td>41,3</td>

                      <td>142,000</td>
                    </tr>

                    <tr>
                      <td>18"</td>

                      <td>742,9</td>

                      <td>82,5</td>

                      <td>533,4</td>

                      <td>546,1</td>

                      <td>461,8</td>

                      <td>117,5</td>

                      <td>654,0</td>

                      <td>20</td>

                      <td>44,4</td>

                      <td>175,000</td>
                    </tr>

                    <tr>
                      <td>20"</td>

                      <td>812,8</td>

                      <td>88,9</td>

                      <td>584,2</td>

                      <td>609,6</td>

                      <td>513,1</td>

                      <td>127,0</td>

                      <td>723,9</td>

                      <td>24</td>

                      <td>44,4</td>

                      <td>232,000</td>
                    </tr>

                    <tr>
                      <td>22"</td>

                      <td>869,9</td>

                      <td>95,2</td>

                      <td>641,2</td>

                      <td>666,7</td>

                      <td>564,4</td>

                      <td>133,3</td>

                      <td>777,9</td>

                      <td>24</td>

                      <td>47,6</td>

                      <td>292,000</td>
                    </tr>

                    <tr>
                      <td>24"</td>

                      <td>939,8</td>

                      <td>101,6</td>

                      <td>692,1</td>

                      <td>717,5</td>

                      <td>615,9</td>

                      <td>139,7</td>

                      <td>838,2</td>

                      <td>24</td>

                      <td>50,8</td>

                      <td>330,000</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <br />

              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-4">
                  Weld Neck Flange Applications:
                </h3>
                <ul className="list-disc list-inside">
                  <li>High-pressure and high-temperature applications</li>
                  <li>Critical services where leak prevention is paramount</li>
                  <li>Hazardous fluids and gases</li>
                  <li>Refinery, petrochemical, and chemical industries</li>
                  <li>
                    Used in power plants, shipbuilding, and gas processing
                    industries
                  </li>
                  <li>Provides reinforcement in high-stress environments</li>
                </ul>
              </div>
            </div>
            <div className="lg:w-1/4 mt-8 lg:mt-0 hidden sm:block">
              <img
                src={weldNeckFlanges}
                alt="Hex Nuts"
                className="w-full h-auto mb-6 rounded shadow-lg"
              />
              {/* <MaterialGrade /> */}
              <RelatedProducts />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default WeldNeckFlanges;
