import React from 'react'
import Layout from "../../common/Layout";
import RelatedProducts from "../../ProductComponent/RelatedProducts";
import MaterialGrade from "../MaterialGrade.jsx";
import ssfastners from "../../../assets/newImages/ssfastners.jpg";
import AnimatedBars from "../AnimatedBars.jsx";
const StainlessSteel304_304LFasteners = () => {
  return (
    <Layout>
    <div>
      <div className="text-fontDefault p-4 prod">
        <div className="customContainer  text-fontDefault p-6 shadow-md rounded-md flex flex-col lg:flex-row">
          <div className="lg:w-3/4 lg:pr-2">
            <h1 className="text-xl md:text-3xl font-bold mb-4 ">
            READY STOCK OF SS 304 ANCHOR FASTENERS, SS 304L BOLTS, 304L SS SOCKET SET SCREW, MANUFACTURER OF ASTM A193 304 FASTENERS, STAINLESS STEEL 304 THREADED ROD SUPPLIER
            </h1>
{/* 
            <div className="lg:w-1/4 mt-8 lg:mt-0 sm:block md:hidden">
              <img
                src={ssfastners}
                alt="Anchor Bolts"
                className="w-full h-auto mb-6 rounded shadow-lg"
              />
            </div> */}

            <div className="mb-6">
              <p className="mb-2 text-justify">
              Hariom Steel is a recognized seller and manufacturer of SS 304 / 304L Fasteners, made with high-quality materials and advanced technology. Our Stainless Steel UNS S30400 Fasteners are precisely crafted from high-grade raw materials using sophisticated technology. These fasteners are commonly used as flow control mechanisms to manage the passage of fluid through specific sections of a pipe. They feature excellent design, fine finish, proper cleaning, and other notable characteristics. Stainless Steel WNR 1.4301 Fasteners are known for their increased useful life, rust resistance, dimensional precision, and high compressive strength. Offered at industry-leading prices, these fasteners are available in a range of grades and styles to meet various industry-specific needs. We provide our customers with competitive pricing and a diverse selection of this alloy.
              </p>

              <div className="mt-5 mb-5  flex items-center justify-center">
                <img src={ssfastners} alt="" width={600} />
              </div>

                {/* specification */}
                <h2 className="mb-4 mt-4 font-bold text-2xl">Stainless Steel 304/304L Fasteners Specifications</h2>
                                <div className="overflow-x-auto">
                                <table>
                                <tbody>
                                <tr>
                                <th>Specification</th>
                                <td>ASTM A194, ASME SA193</td>
                                </tr>
                                <tr>
                                <th>Standard</th>
                                <td>DIN, UNI, ISO, JIS, GB, IS, BS, ASME, ANSI, ASTM and all International Standards</td>
                                </tr>
                                <tr>
                                <th>Size</th>
                                <td>M2, M2.5, M3, M4, M5, M6, M8, M10, M12, M14, M16, M20, M24, M30, M36 to M160</td>
                                </tr>
                                <tr>
                                <th>Length</th>
                                <td>3 mm to 200 mm</td>
                                </tr>
                                <tr>
                                <th>Threads</th>
                                <td>METRIC, BSW, BSF, UNC, UNF or as required</td>
                                </tr>
                                <tr>
                                <th width="30%">Standard</th>
                                <td width="70%">ANSI – American National Standards Institute
                                <ul>
                                    <li>ANSI B 18.2.4 2M</li>
                                    <li>ANSI B 28.2.4 1M</li>
                                    <li>ASME B18.2.2</li>
                                    <li>ASME B18.5</li>
                                </ul>
                                <strong>DIN:&nbsp;</strong>DIN 931, DIN 933, DIN 934, DIN 970

                                <strong>UTS – Unified Thread Standard :</strong>&nbsp;UNC, UNF, UNEF, UNS, NPT, NPTF

                                <strong>ISO – International Organization for Standardization</strong>&nbsp;: ISO 4032, ISO 4033, JIS standards</td>
                                </tr>
                                <tr>
                                <th>In Form of</th>
                                <td>Hex, Square, Round, Threading as per Gauge Etc.</td>
                                </tr>
                                <tr>
                                <th>Head drive</th>
                                <td>Phillips, Slotted, Phillips/Slot, Six-Lobe, etc</td>
                                </tr>
                                <tr>
                                <th>Test Certificate:</th>
                                <td>Manufacturer Test Certificate as per EN 10204 / 3.1</td>
                                </tr>
                                <tr>
                                <th>Surface finishing</th>
                                <td>Zinc (yellow, white, blue, black), black oxide,
                                Geometry, Dacroment, anodizing, nickel plated, plated zinc-nickel, Stainless steel: Passivated
                                Self-Colour, Hot Dip Galvanised (HDG), Bright Zinc Plated (BZP), Sherardized, Stainless Steel, Mechanically Applied coating and some special one like DACROMET</td>
                                </tr>
                                <tr>
                                <th>Production process</th>
                                <td>M2-M24: Cold Froging, hot forging M24-M100, machining and CNC for Customized connecting element</td>
                                </tr>
                                <tr>
                                <th>Surface Coating</th>
                                <td>
                                <ul>
                                    <li>Teflon Coated Fasteners</li>
                                    <li>Phosphate Coated Fasteners</li>
                                    <li>Zinc Coated Fasteners</li>
                                    <li>Ptfe Coated</li>
                                    <li>Xylon Coated Fasteners</li>
                                    <li>Cadmium Coated Fasteners</li>
                                    <li>Hot Dipped Galvanizing Coated Fasteners</li>
                                </ul>
                                All surface finishes offered including<strong>&nbsp;Geomet &amp; PTFE, Galvanised,&nbsp;<strong>Zinc,&nbsp;</strong>Electroless Nickel, Sherardized, Dacromet</strong></td>
                                </tr>
                                <tr>
                                <th>Packing</th>
                                <td>IN BULK: canton, pallet, Small Boxs / carton / pallet, or customer request</td>
                                </tr>
                                </tbody>
                                </table>
                                </div><br />

                                <h2 className="mb-4 mt-4 font-bold text-2xl">SS 304/304L Fasteners Equivalent Grades</h2>
                        <div className="overflow-x-auto">
                        <table>
                        <tbody>
                                    <tr className='bg-primary text-white'>
                                    <td><strong>STANDARD</strong></td>
                                    <td><strong>WERKSTOFF NR.</strong></td>
                                    <td><strong>UNS</strong></td>
                                    <td><strong>JIS</strong></td>
                                    <td><strong>BS</strong></td>
                                    <td><strong>GOST</strong></td>
                                    <td><strong>AFNOR</strong></td>
                                    <td><strong>EN</strong></td>
                                    </tr>
                                    <tr>
                                    <td><strong>SS 304</strong></td>
                                    <td>1.4301</td>
                                    <td>S30400</td>
                                    <td>SUS 304</td>
                                    <td>304S31</td>
                                    <td>08Х18Н10</td>
                                    <td>Z7CN18‐09</td>
                                    <td>X5CrNi18-10</td>
                                    </tr>
                                    <tr>
                                    <td><strong>SS 304L</strong></td>
                                    <td>1.4306 / 1.4307</td>
                                    <td>S30403</td>
                                    <td>SUS 304L</td>
                                    <td>3304S11</td>
                                    <td>03Х18Н11</td>
                                    <td>Z3CN18‐10</td>
                                    <td>X2CrNi18-9 / X2CrNi19-11</td>
                                    </tr>
                                    </tbody>
                        </table>
                        </div><br />

                        <h2 className="mb-4 mt-4 font-bold text-2xl">Stainless Steel 304/304L Fasteners Chemical Composition</h2>
                        <div className="overflow-x-auto">
                        <table>
                        <tbody>
                                    <tr className='bg-primary text-white'>
                                    <td><strong>Grade</strong></td>
                                    <td><strong>C</strong></td>
                                    <td><strong>Mn</strong></td>
                                    <td><strong>Si</strong></td>
                                    <td><strong>P</strong></td>
                                    <td><strong>S</strong></td>
                                    <td><strong>Cr</strong></td>
                                    <td><strong>Mo</strong></td>
                                    <td><strong>Ni</strong></td>
                                    <td><strong>N</strong></td>
                                    </tr>
                                    <tr>
                                    <td><strong>SS 304</strong></td>
                                    <td>0.08 max</td>
                                    <td>2 max</td>
                                    <td>0.75 max</td>
                                    <td>0.045 max</td>
                                    <td>0.030 max</td>
                                    <td>18 – 20</td>
                                    <td>–</td>
                                    <td>8 – 11</td>
                                    <td>–</td>
                                    </tr>
                                    <tr>
                                    <td><strong>SS 304L</strong></td>
                                    <td>0.35 max</td>
                                    <td>2 max</td>
                                    <td>1.00 max</td>
                                    <td>0.045 max</td>
                                    <td>0.03 max</td>
                                    <td>18 – 20</td>
                                    <td>–</td>
                                    <td>8 – 13</td>
                                    <td>–</td>
                                    </tr>
                                    </tbody>
                        </table>
                        </div><br />
                        
                        <h2 className="mb-4 mt-4 font-bold text-2xl">304/304L Stainless Steel Fasteners Mechanical Properties</h2>
                        <div className="overflow-x-auto">
                        <table>
                        <tbody>
                                    <tr className='bg-primary text-white'>
                                    <td><strong>Grade</strong></td>
                                    <td><strong>Density</strong></td>
                                    <td><strong>Melting Point</strong></td>
                                    <td><strong>Tensile Strength</strong></td>
                                    <td><strong>Yield Strength (0.2%Offset)</strong></td>
                                    <td><strong>Elongation</strong></td>
                                    </tr>
                                    <tr>
                                    <td>304</td>
                                    <td>8.0 g/cm3</td>
                                    <td>1400 °C (2550 °F)</td>
                                    <td>Psi – 75000 , MPa – 515</td>
                                    <td>Psi – 30000 , MPa – 205</td>
                                    <td>35 %</td>
                                    </tr>
                                    <tr>
                                    <td>304L</td>
                                    <td>8.0 g/cm3</td>
                                    <td>1400 °C (2550 °F)</td>
                                    <td>Psi – 75000 , MPa – 515</td>
                                    <td>Psi – 30000 , MPa – 205</td>
                                    <td>35 %</td>
                                    </tr>
                                    </tbody>
                        </table>
                        </div><br />

                        <h2 className="mb-4 mt-4 font-bold text-2xl">Stainless Steel 304/304L Fasteners Standard</h2>
                        <div className="overflow-x-auto">
                        <table>
                    <thead>
                    <tr>
                      <th><strong>Hexagon Bolt / Set Screws</strong></th>
                      <th><strong>Stud Bolt</strong></th>
                      <th><strong>Hexagon Nuts</strong></th>
                      <th><strong>Socket Cap screws</strong></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>DIN 931</td>
                      <td>BS4882</td>
                      <td>DIN 934</td>
                      <td>DIN 912</td>
                    </tr>
                    <tr>
                      <td>DIN 933</td>
                      <td>DIN 976</td>
                      <td>BS 1768</td>
                      <td>DIN 6912</td>
                    </tr>
                    <tr>
                      <td>BS 1768</td>
                      <td></td>
                      <td>BS 1769</td>
                      <td>DIN 7984</td>
                    </tr>
                    <tr>
                      <td>UNI 5731</td>
                      <td></td>
                      <td>BS 1083</td>
                      <td>ANSI B18.3</td>
                    </tr>
                    <tr>
                      <td>UNI 5739</td>
                      <td></td>
                      <td>BS 3692</td>
                      <td>ISO 4762</td>
                    </tr>
                    <tr>
                      <td>ISO 4014</td>
                      <td></td>
                      <td>ISO 4032</td>
                      <td>UNI 5931</td>
                    </tr>
                    <tr>
                      <td>ISO 4017</td>
                      <td></td>
                      <td>ISO 4033</td>
                      <td>BS 2470</td>
                    </tr>
                    <tr>
                      <td>BS 1769</td>
                      <td></td>
                      <td>ANSI B18.2.2</td>
                      <td>BS 4168</td>
                    </tr>
                    <tr>
                      <td>BS 3692</td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>ANSI B18.2.1</td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                  <thead>
                    <tr>
                      <th><strong>Socket &amp; Slotted Set Screws</strong></th>
                      <th><strong>Socket &amp; Slotted Countersunk Screws</strong></th>
                      <th><strong>Studs</strong></th>
                      <th><strong>Washers</strong></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>DIN 913</td>
                      <td>DIN 963</td>
                      <td>DIN 938</td>
                      <td>DIN 125</td>
                    </tr>
                    <tr>
                      <td>DIN 914</td>
                      <td>DIN 7991</td>
                      <td>DIN 939</td>
                      <td>DIN 9021</td>
                    </tr>
                    <tr>
                      <td>DIN 915</td>
                      <td>BS 4168</td>
                      <td>DIN 2510</td>
                      <td>BS 4320</td>
                    </tr>
                    <tr>
                      <td>DIN 916</td>
                      <td>BS 1981</td>
                      <td>BS 4439</td>
                      <td>BS 3410</td>
                    </tr>
                    <tr>
                      <td>DIN 551</td>
                      <td>BS 483</td>
                      <td>BS 2693</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>DIN 553</td>
                      <td>BS 2470</td>
                      <td></td>
                      <td>Spring Washers</td>
                    </tr>
                    <tr>
                      <td>DIN 438</td>
                      <td></td>
                      <td></td>
                      <td>DIN 127</td>
                    </tr>
                    <tr>
                      <td>BS 4168</td>
                      <td></td>
                      <td></td>
                      <td>BS 4464</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>BS 1802</td>
                    </tr>
                  </tbody>
                        </table>
                        </div><br />
                        <h2 className="mb-4 mt-4 font-bold text-2xl">Weight Chart Of Stainless Steel 304/304L Fasteners</h2> 
            <div className="overflow-x-auto">
            <table>
            <tbody>
             <tr className='bg-primary text-white'>
             <td colSpan="11"><strong> AS PER IS 1363/67 MM THREADS</strong></td>
             </tr>
             <tr className='bg-primary text-white'>
             <td colSpan="11"><em><strong>FOR 100 NOS APPROXIMATE WEIGHT IN KGS </strong></em></td>
             </tr>
             <tr className='bg-primary text-white'>
             <td rowSpan="2"><strong>LENGTH</strong></td>
             <td colSpan="10"><strong> Stainless Steel Fasteners DIAMETER</strong></td>
             </tr >
             <tr className='bg-primary text-white'>
             <td><strong>M 6</strong></td>
             <td><strong>M 5</strong></td>
             <td><strong>M 8</strong></td>
             <td><strong>M 12</strong></td>
             <td><strong>M 10</strong></td>
             <td><strong>M 14</strong></td>
             <td><strong>M 18</strong></td>
             <td><strong>M 22</strong></td>
             <td><strong>M 16</strong></td>
             <td><strong>M 20</strong></td>
             </tr>
             <tr>
             <td><strong>16 mm</strong></td>
             <td>0.743</td>
             <td>0.448</td>
             <td>1.590</td>
             <td></td>
             <td>3.211</td>
             <td></td>
             <td></td>
             <td></td>
             <td></td>
             <td></td>
             </tr>
             <tr>
             <td><strong>20 mm</strong></td>
             <td>0.812</td>
             <td>0.598</td>
             <td>1.711</td>
             <td>4.892</td>
             <td>3.411</td>
             <td></td>
             <td></td>
             <td></td>
             <td></td>
             <td></td>
             </tr>
             <tr>
             <td><strong>25 mm</strong></td>
             <td>0.897</td>
             <td>0.660</td>
             <td>1.871</td>
             <td>5.252</td>
             <td>3.660</td>
             <td>8.170</td>
             <td></td>
             <td></td>
             <td></td>
             <td></td>
             </tr>
             <tr>
             <td><strong>30 mm</strong></td>
             <td>1.010</td>
             <td>0.737</td>
             <td>2.070</td>
             <td>5.574</td>
             <td>3.912</td>
             <td>9.690</td>
             <td></td>
             <td></td>
             <td>10.707</td>
             <td>18.657</td>
             </tr>
             <tr>
             <td><strong>35 mm</strong></td>
             <td>1.120</td>
             <td>0.804</td>
             <td>2.271</td>
             <td>5.974</td>
             <td>4.223</td>
             <td>10.000</td>
             <td>16.026</td>
             <td></td>
             <td>11.416</td>
             <td>19.608</td>
             </tr>
             <tr>
             <td><strong>40 mm</strong></td>
             <td>1.230</td>
             <td>0.881</td>
             <td>2.470</td>
             <td>6.410</td>
             <td>4.533</td>
             <td>10.288</td>
             <td>16.667</td>
             <td></td>
             <td>12.107</td>
             <td>20.747</td>
             </tr>
             <tr>
             <td><strong>45 mm</strong></td>
             <td>1.340</td>
             <td>0.958</td>
             <td>2.671</td>
             <td>6.859</td>
             <td>4.840</td>
             <td>10.504</td>
             <td>17.241</td>
             <td></td>
             <td>12.821</td>
             <td>21.739</td>
             </tr>
             <tr>
             <td><strong>50 mm</strong></td>
             <td>1.450</td>
             <td>1.030</td>
             <td>2.870</td>
             <td>7.299</td>
             <td>5.155</td>
             <td>11.261</td>
             <td>17.857</td>
             <td>28.409</td>
             <td>13.624</td>
             <td>22.727</td>
             </tr>
             <tr>
             <td><strong>55 mm</strong></td>
             <td>1.560</td>
             <td></td>
             <td>3.071</td>
             <td>7.740</td>
             <td>5.464</td>
             <td>11.628</td>
             <td>18.868</td>
             <td>30.120</td>
             <td>14.409</td>
             <td>23.810</td>
             </tr>
             <tr>
             <td><strong>60 mm</strong></td>
             <td>1.670</td>
             <td></td>
             <td>3.270</td>
             <td>8.183</td>
             <td>5.774</td>
             <td>12.255</td>
             <td>20.000</td>
             <td>32.051</td>
             <td>15.244</td>
             <td>25.126</td>
             </tr>
             <tr>
             <td><strong>65 mm</strong></td>
             <td>1.781</td>
             <td></td>
             <td>3.472</td>
             <td>8.621</td>
             <td>6.083</td>
             <td>12.626</td>
             <td>21.008</td>
             <td>34.014</td>
             <td>16.026</td>
             <td>26.455</td>
             </tr>
             <tr>
             <td><strong>70 mm</strong></td>
             <td>1.890</td>
             <td></td>
             <td>3.671</td>
             <td>9.058</td>
             <td>6.394</td>
             <td>12.987</td>
             <td>22.124</td>
             <td>35.461</td>
             <td>16.835</td>
             <td>27.778</td>
             </tr>
             <tr>
             <td><strong>75 mm</strong></td>
             <td>2.000</td>
             <td></td>
             <td>3.873</td>
             <td>9.506</td>
             <td>6.702</td>
             <td>13.228</td>
             <td>23.256</td>
             <td>39.063</td>
             <td>17.606</td>
             <td>29.070</td>
             </tr>
             <tr>
             <td><strong>80 mm</strong></td>
             <td>2.111</td>
             <td></td>
             <td>4.072</td>
             <td>10.000</td>
             <td>7.013</td>
             <td>13.889</td>
             <td>24.510</td>
             <td>40.000</td>
             <td>18.248</td>
             <td>30.303</td>
             </tr>
             <tr>
             <td><strong>90 mm</strong></td>
             <td></td>
             <td></td>
             <td>4.472</td>
             <td>10.917</td>
             <td>7.634</td>
             <td>14.925</td>
             <td>26.042</td>
             <td>41.667</td>
             <td>19.841</td>
             <td>33.113</td>
             </tr>
             <tr>
             <td><strong>100 mm</strong></td>
             <td></td>
             <td></td>
             <td>4.873</td>
             <td>11.820</td>
             <td>8.251</td>
             <td>16.340</td>
             <td>27.778</td>
             <td>46.296</td>
             <td>21.459</td>
             <td>35.714</td>
             </tr>
             <tr>
             <td><strong>110 mm</strong></td>
             <td></td>
             <td></td>
             <td></td>
             <td>12.723</td>
             <td>8.881</td>
             <td>17.730</td>
             <td>31.250</td>
             <td>47.170</td>
             <td>23.041</td>
             <td>38.168</td>
             </tr>
             <tr>
             <td><strong>120 mm</strong></td>
             <td></td>
             <td></td>
             <td></td>
             <td>13.624</td>
             <td>9.506</td>
             <td>18.797</td>
             <td>32.895</td>
             <td>50.000</td>
             <td>24.631</td>
             <td>40.984</td>
             </tr>
             <tr>
             <td><strong>130 mm</strong></td>
             <td></td>
             <td></td>
             <td></td>
             <td>14.535</td>
             <td>10.101</td>
             <td>20.000</td>
             <td>34.722</td>
             <td>52.632</td>
             <td>26.316</td>
             <td>43.478</td>
             </tr>
             <tr>
             <td><strong>140 mm</strong></td>
             <td></td>
             <td></td>
             <td></td>
             <td>15.432</td>
             <td>10.707</td>
             <td>20.833</td>
             <td>35.461</td>
             <td>55.556</td>
             <td>27.933</td>
             <td>46.296</td>
             </tr>
             <tr>
             <td><strong>150 mm</strong></td>
             <td></td>
             <td></td>
             <td></td>
             <td>16.340</td>
             <td>11.312</td>
             <td>22.222</td>
             <td>37.594</td>
             <td>58.824</td>
             <td>29.412</td>
             <td>48.544</td>
             </tr>
             <tr>
             <td><strong>160 mm</strong></td>
             <td></td>
             <td></td>
             <td></td>
             <td>17.241</td>
             <td>11.905</td>
             <td>24.272</td>
             <td>40.984</td>
             <td>61.728</td>
             <td>31.056</td>
             <td>50.505</td>
             </tr>
             <tr>
             <td><strong>170 mm</strong></td>
             <td></td>
             <td></td>
             <td></td>
             <td>18.116</td>
             <td>12.500</td>
             <td></td>
             <td></td>
             <td>64.935</td>
             <td>32.680</td>
             <td>53.191</td>
             </tr>
             <tr>
             <td><strong>180 mm</strong></td>
             <td></td>
             <td></td>
             <td></td>
             <td>19.011</td>
             <td>13.123</td>
             <td>26.882</td>
             <td></td>
             <td>67.568</td>
             <td>34.247</td>
             <td>55.556</td>
             </tr>
             <tr>
             <td><strong>190 mm</strong></td>
             <td></td>
             <td></td>
             <td></td>
             <td>19.920</td>
             <td>13.736</td>
             <td></td>
             <td></td>
             <td>70.423</td>
             <td>35.971</td>
             <td>58.140</td>
             </tr>
             <tr>
             <td><strong>200 mm</strong></td>
             <td></td>
             <td></td>
             <td></td>
             <td>20.833</td>
             <td>14.327</td>
             <td>29.586</td>
             <td>49.020</td>
             <td>73.529</td>
             <td>37.594</td>
             <td>60.976</td>
             </tr>
             <tr>
             <td><strong>220 mm</strong></td>
             <td></td>
             <td></td>
             <td></td>
             <td>22.624</td>
             <td>15.528</td>
             <td></td>
             <td></td>
             <td>79.365</td>
             <td>40.650</td>
             <td>65.789</td>
             </tr>
             <tr>
             <td><strong>240 mm</strong></td>
             <td></td>
             <td></td>
             <td></td>
             <td>24.510</td>
             <td>16.722</td>
             <td></td>
             <td></td>
             <td>84.746</td>
             <td>43.860</td>
             <td>71.429</td>
             </tr>
             <tr>
             <td><strong>250 mm</strong></td>
             <td></td>
             <td></td>
             <td></td>
             <td>25.381</td>
             <td>17.301</td>
             <td></td>
             <td></td>
             <td>87.719</td>
             <td>45.455</td>
             <td>74.627</td>
             </tr>
             <tr>
             <td><strong>260 mm</strong></td>
             <td></td>
             <td></td>
             <td></td>
             <td>26.316</td>
             <td>17.921</td>
             <td></td>
             <td></td>
             <td>90.909</td>
             <td>47.170</td>
             <td>76.923</td>
             </tr>
             <tr>
             <td><strong>280 mm</strong></td>
             <td></td>
             <td></td>
             <td></td>
             <td>28.090</td>
             <td>19.157</td>
             <td></td>
             <td></td>
             <td>98.039</td>
             <td>50.505</td>
             <td>81.967</td>
             </tr>
             <tr>
             <td><strong>300 mm</strong></td>
             <td></td>
             <td></td>
             <td></td>
             <td>29.940</td>
             <td>20.325</td>
             <td></td>
             <td></td>
             <td>104.167</td>
             <td>53.763</td>
             <td>87.719</td>
             </tr>
             <tr>
             <td><strong>320 mm</strong></td>
             <td></td>
             <td></td>
             <td></td>
             <td>31.646</td>
             <td></td>
             <td></td>
             <td></td>
             <td></td>
             <td>56.818</td>
             <td>92.593</td>
             </tr>
             <tr>
             <td><strong>340 mm</strong></td>
             <td></td>
             <td></td>
             <td></td>
             <td>33.557</td>
             <td></td>
             <td></td>
             <td></td>
             <td></td>
             <td>60.241</td>
             <td>98.039</td>
             </tr>
             <tr>
             <td><strong>360 mm</strong></td>
             <td></td>
             <td></td>
             <td></td>
             <td>35.211</td>
             <td></td>
             <td></td>
             <td></td>
             <td></td>
             <td>63.291</td>
             <td>104.167</td>
             </tr>
             <tr>
             <td><strong>380 mm</strong></td>
             <td></td>
             <td></td>
             <td></td>
             <td>37.037</td>
             <td></td>
             <td></td>
             <td></td>
             <td></td>
             <td>66.667</td>
             <td>108.696</td>
             </tr>
             <tr>
             <td><strong>400 mm</strong></td>
             <td></td>
             <td></td>
             <td></td>
             <td>39.063</td>
             <td></td>
             <td></td>
             <td></td>
             <td>&nbsp;</td>
             <td>69.444</td>
             <td>113.636</td>
             </tr>
             </tbody>
            </table>
            </div><br />
            <h2 className="mb-4 mt-4 font-bold text-2xl">SS 304/304L Fasteners Torque Chart</h2> 
            <div className="overflow-x-auto">
            <table>
            <tbody>
                                    <tr className='bg-primary text-white'>
                                      <th colSpan="8"><strong>Torque Specifications</strong></th>
                                    </tr>
                                    <tr className='bg-primary text-white'>
                                      <td rowSpan="2"><strong>Bolt Size<br />
                                          Inches or #</strong>
                                      </td>
                                      <td rowSpan="2"><strong>Threads<br />
                                          Per Inch</strong>
                                      </td>
                                      <td colSpan="6"><strong>Standard Dry Torque in Inch-Pounds</strong>
                                      </td>
                                    </tr>
                                    <tr className='bg-primary text-white'>
                                      <td>304/304L<br />
                                        Stainless Steel
                                      </td>
                                      <td>304/304L<br />
                                        Stainless Steel
                                      </td>
                                    </tr>
                                    <tr>
                                      <td rowSpan="2">#2</td>
                                      <td>56</td>
                                      <td>2.5</td>
                                      <td>2.6</td>
                                    </tr>
                                    <tr>
                                      <td>64</td>
                                      <td>3.0</td>
                                      <td>3.2</td>
                                    </tr>
                                    <tr>
                                      <td rowSpan="2">#3</td>
                                      <td>48</td>
                                      <td>3.9</td>
                                      <td>4.0</td>
                                    </tr>
                                    <tr>
                                      <td>56</td>
                                      <td>4.4</td>
                                      <td>4.6</td>
                                    </tr>
                                    <tr>
                                      <td rowSpan="2">#4</td>
                                      <td>40</td>
                                      <td>5.2</td>
                                      <td>5.5</td>
                                    </tr>
                                    <tr>
                                      <td>48</td>
                                      <td>6.6</td>
                                      <td>6.9</td>
                                    </tr>
                                    <tr>
                                      <td rowSpan="2">#5</td>
                                      <td>40</td>
                                      <td>7.7</td>
                                      <td>8.1</td>
                                    </tr>
                                    <tr>
                                      <td>44</td>
                                      <td>9.4</td>
                                      <td>9.8</td>
                                    </tr>
                                    <tr>
                                      <td rowSpan="2">#6</td>
                                      <td>32</td>
                                      <td>9.6</td>
                                      <td>10.1</td>
                                    </tr>
                                    <tr>
                                      <td>40</td>
                                      <td>12.1</td>
                                      <td>12.7</td>
                                    </tr>
                                    <tr>
                                      <td rowSpan="2">#8</td>
                                      <td>32</td>
                                      <td>19.8</td>
                                      <td>20.7</td>
                                    </tr>
                                    <tr>
                                      <td>36</td>
                                      <td>22.0</td>
                                      <td>23.0</td>
                                    </tr>
                                    <tr>
                                      <td rowSpan="2">#10</td>
                                      <td>24</td>
                                      <td>22.8</td>
                                      <td>23.8</td>
                                    </tr>
                                    <tr>
                                      <td>32</td>
                                      <td>31.7</td>
                                      <td>33.1</td>
                                    </tr>
                                    <tr className='bg-primary text-white'>
                                      <td rowSpan="2"><strong>Bolt Size<br />
                                          Inches or #</strong>
                                      </td>
                                      <td rowSpan="2"><strong>Threads<br />
                                          Per Inch</strong>
                                      </td>
                                      <td colSpan="6"><strong>Standard Dry Torque in Inch-Pounds</strong>
                                      </td>
                                    </tr>
                                    <tr className='bg-primary text-white'>
                                      <td>304/304L<br />
                                        Stainless Steel
                                      </td>
                                      <td>304/304L<br />
                                        Stainless Steel
                                      </td>
                                    </tr>
                                    <tr>
                                      <td rowSpan="2">1/4</td>
                                      <td>20</td>
                                      <td>75.2</td>
                                      <td>78.8</td>
                                    </tr>
                                    <tr>
                                      <td>28</td>
                                      <td>94.0</td>
                                      <td>99.0</td>
                                    </tr>
                                    <tr>
                                      <td rowSpan="2">5/16</td>
                                      <td>18</td>
                                      <td>132</td>
                                      <td>138</td>
                                    </tr>
                                    <tr>
                                      <td>24</td>
                                      <td>142</td>
                                      <td>147</td>
                                    </tr>
                                    <tr>
                                      <td rowSpan="2">3/8</td>
                                      <td>16</td>
                                      <td>236</td>
                                      <td>247</td>
                                    </tr>
                                    <tr>
                                      <td>24</td>
                                      <td>259</td>
                                      <td>271</td>
                                    </tr>
                                    <tr>
                                      <td rowSpan="2">7/16</td>
                                      <td>14</td>
                                      <td>376</td>
                                      <td>393</td>
                                    </tr>
                                    <tr>
                                      <td>20</td>
                                      <td>400</td>
                                      <td>418</td>
                                    </tr>
                                    <tr>
                                      <td rowSpan="2">1/2</td>
                                      <td>13</td>
                                      <td>517</td>
                                      <td>542</td>
                                    </tr>
                                    <tr>
                                      <td>20</td>
                                      <td>541</td>
                                      <td>565</td>
                                    </tr>
                                    <tr className='bg-primary text-white'>
                                      <td rowSpan="2"><strong>Bolt Size<br />
                                          Inches or #</strong>
                                      </td>
                                      <td rowSpan="2"><strong>Threads<br />
                                          Per Inch</strong>
                                      </td>
                                      <td colSpan="6"><strong>Standard Dry Torque in Inch-Pounds</strong>
                                      </td>
                                    </tr>
                                    <tr className='bg-primary text-white'>
                                      <td>304/304L<br />
                                        Stainless Steel
                                      </td>
                                      <td>304/304L<br />
                                        Stainless Steel
                                      </td>
                                    </tr>
                                    <tr>
                                      <td rowSpan="2">9/16</td>
                                      <td>12</td>
                                      <td>682</td>
                                      <td>713</td>
                                    </tr>
                                    <tr>
                                      <td>18</td>
                                      <td>752</td>
                                      <td>787</td>
                                    </tr>
                                    <tr>
                                      <td rowSpan="2">5/8</td>
                                      <td>11</td>
                                      
                                      <td>1110</td>
                                      <td>1160</td>
                                    </tr>
                                    <tr>
                                      <td>18</td>
                                      <td>1244</td>
                                      <td>1301</td>
                                    </tr>
                                    <tr>
                                      <td rowSpan="2">3/4</td>
                                      <td>10</td>
                                      <td>1530</td>
                                      <td>1582</td>
                                    </tr>
                                    <tr>
                                      <td>16</td>
                                      <td>1490</td>
                                      <td>1558</td>
                                    </tr>
                                    <tr>
                                      <td rowSpan="2">7/8</td>
                                      <td>9</td>
                                      <td>2328</td>
                                      <td>2430</td>
                                    </tr>
                                    <tr>
                                      <td>14</td>
                                      <td>2318</td>
                                      <td>2420</td>
                                    </tr>
                                    <tr>
                                      <td rowSpan="2">1</td>
                                      <td>8</td>
                                      <td>3440</td>
                                      <td>3595</td>
                                    </tr>
                                    <tr>
                                      <td>14</td>
                                      <td>3110</td>
                                      <td>3250</td>
                                    </tr>
                                    <tr className='bg-primary text-white'>
                                      <td rowSpan="2"><strong>Bolt Size<br />
                                          Inches or #</strong>
                                      </td>
                                      <td rowSpan="2"><strong>Threads<br />
                                          Per Inch</strong>
                                      </td>
                                      <td colSpan="6"><strong>Standard Dry Torque in Foot-Pounds</strong>
                                      </td>
                                    </tr>
                                    <tr className='bg-primary text-white'>
                                      <td>304/304L<br />
                                        Stainless Steel
                                      </td>
                                      <td>304/304L<br />
                                        Stainless Steel
                                      </td>
                                    </tr>
                                    <tr>
                                      <td rowSpan="2">1 1/8</td>
                                      <td>7</td>
                                      <td>413</td>
                                      <td>432</td>
                                    </tr>
                                    <tr>
                                      <td>12</td>
                                      <td>390</td>
                                      <td>408</td>
                                    </tr>
                                    <tr>
                                      <td rowSpan="2">1 1/4</td>
                                      <td>7</td>
                                      <td>523</td>
                                      <td>546</td>
                                    </tr>
                                    <tr>
                                      <td>12</td>
                                      <td>480</td>
                                      <td>504</td>
                                    </tr>
                                    <tr>
                                      <td rowSpan="2">1 1/2</td>
                                      <td>6</td>
                                      <td>888</td>
                                      <td>930</td>
                                    </tr>
                                    <tr>
                                      <td>12</td>
                                      <td>703</td>
                                      <td>732</td>
                                    </tr>
                                  </tbody>
            </table>
            </div><br />

                {/* chemical composition */}
                {/* mechnical property */}
                {/* weight chart */}
                {/* dimensionchart */}
            

            

            

           

              <div>
                <h3 className="text-xl font-semibold mb-4">Applications:</h3>

                <AnimatedBars />
              </div>
            </div>
          </div>
          {/* ------------ */}
          <div className="lg:w-1/4 mt-8 lg:mt-0 hidden sm:block">
            <img
              src={ssfastners}
              alt="Anchor Bolts"
              className="w-full h-auto mb-6 rounded shadow-lg"
            />
            <MaterialGrade />
            <RelatedProducts />
          </div>
        </div>
      </div>
    </div>
  </Layout>

  )
}

export default StainlessSteel304_304LFasteners